<template>
    <div v-if="company && company.reputation_risks && company.reputation_risks.flags && company.reputation_risks.flags?.length > 0">
      <router-link 
        v-for="(flag, index) in company.reputation_risks.flags" 
        :key="index"
        :to="getRouterLinkTo(flag)"
        class="text-bg-warning badge me-1 text-decoration-none clickable"
      >
        {{ getFlagText(flag) }}
      </router-link>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import { companiesEmits } from '@/components/Companies/CompaniesUtils.js';
  
  export default defineComponent({
    props: {
      company: {
        type: Object,
        required: true
      }
    },
    emits: companiesEmits,
    setup(props) {
      const getFlagText = (flag: string): string => {
        switch (flag) {
          case 'legal_flag':
            return 'Legal Items';
          case 'user_reviews_flag':
            return 'Customer Feedback';
          case 'employee_reviews_flag':
            return 'Employee Feedback';
          default:
            return 'Unknown';
        }
      };
  
      const getFlagSection = (flag: string): string => {
        switch (flag) {
          case 'legal_flag':
            return 'report-LEGAL';
          case 'user_reviews_flag':
            return 'report-UREVIEWS';
          case 'employee_reviews_flag':
            return 'report-EREVIEWS';
          default:
            return '';
        }
      };
  
      const getRouterLinkTo = (flag: string) => {
        const section = getFlagSection(flag);
        return {
          path: '/company-report/',
          query: {
            company_name: props.company.name,
            company_website: props.company.website,
            tab: 'company',
            section: section
          }
        };
      };
  
      return {
        getFlagText,
        getRouterLinkTo
      };
    }
  });
  </script>