<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive draggable-container"
          ref="tableContainer"
          @mousedown="startDragging"
          @mousemove="drag"
          @mouseup="stopDragging"
          @mouseleave="stopDragging"
        >
          <table class="table table-sm text-nowrap align-middle mb-0 ps-2">
            <thead>
              <tr>
                <th v-for="column in tableConfig" :key="column.key" scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    :class="column.class">{{ column.header }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="companies.length">
                <template v-for="company in companies" :key="company.id">
                  <tr :class="{ 'row-passed': company.status === 'Passed' && !filtersApplied.includes('STATUS=PASD') }">
                    <td v-for="column in tableConfig" :key="column.key"
                        class="shadow-none lh-1 fw-medium" 
                        :class="[column.cellClass, { 'row-passed': company.status === 'Passed' && !filtersApplied.includes('STATUS=PASD') }, { 'text-black-emphasis': company.status !== 'Passed' }]">
                      <component :is="column.component"
                          v-bind="{ ...column.component === 'CompanyText' ? { text: company[column.key] } : { company: company }, key: column.key }" 
                          @company-selected="selectedCompany = $event"
                          @company-checkbox="companyChecked"
                          @update-status="updateStatus"
                      />
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-else>
                <td colspan="11">
                  <div class="d-flex justify-content-center mt-20" >
                    <div class="spinner-border text-primary" role="status" v-if="loading">        
                      <span class="visually-hidden" >Loading...</span>    
                    </div>
                    <div v-else>
                      <p class="text-center text-black">No companies found.</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <CompanyModal :company="selectedCompany" />
  </template>
  
  <script lang="ts">
  import { defineComponent, nextTick, watch, onMounted, onUnmounted, computed, ref, shallowRef, onBeforeUnmount } from "vue";
  import { Popover } from 'bootstrap';
  import { companiesState, getOrderedConfig, loadCellSettings, saveCellSettings } from '../Settings/CompaniesCellState'; 
  import CompanyName from './Cells/CompanyNameCell.vue'
  import CompanyAlignment from './Cells/CompanyAlignmentCell.vue'
  import CompanyAlignmentScore from './Cells/CompanyAlignmentScoreCell.vue'
  import CompanyPredictedOutcome from './Cells/CompanyPredictedOutcome.vue'
  import CompanyDescription from './Cells/CompanyDescriptionCell.vue'
  import CompanyReputationRisks from './Cells/CompanyReputationRisksCell.vue'
  import CompanyStatus from './Cells/CompanyStatusCell.vue'
  import CompanyDescriptors from './Cells/CompanyDescriptorsCell.vue'
  import CompanyText from './Cells/CompanyTextCell.vue'
  import CompanyActions from './Cells/CompanyActionsCell.vue'
  import CompanyFeedback from './Cells/CompanyFeedbackCell.vue'
  import CompanyOptions from './Cells/CompanyOptionsCell.vue'
  import CompanySubmission from './Cells/CompanySubmissionCell.vue'
  import CompanySubmissionDateCell from './Cells/CompanySubmissionDateCell.vue'
  // import CompanyAttachments from './Cells/CompanyAttachmentsCell.vue'
  import CompanyLocation from './Cells/CompanyLocationCell.vue'
  import CompanyModal from './Modal/CompanyModal.vue'
  
  export default defineComponent({
    name: "CompaniesList",
    props: {
      isPrecisionPicks: {
        type: Boolean,
        default: false,
        required: true
      },
      appliedFilters: {
        type: Array,
        default: () => []
      },
      companies: {
        type: Array,
        required: true
      },
      'handle-company-checkbox': {
        type: Function,
        required: true
      },
      listViewSettings: {
        type: Object,
        required: true,
      },
      listViewSettingsOrder: {
        type: Object,
        required: true,
      },
      isActionsEnabled: {
        type: Boolean,
        required: true,
      },
      filtersApplied: {
        type: String,
        required: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      CompanyName,
      CompanyAlignment,
      CompanyAlignmentScore,
      CompanyPredictedOutcome,
      CompanyDescription,
      CompanyDescriptors,
      CompanyReputationRisks,
      CompanyActions,
      CompanyStatus,
      CompanyText,
      CompanyFeedback,
      CompanySubmission,
      CompanySubmissionDateCell,
      // CompanyAttachments,
      CompanyLocation,
      CompanyOptions,
      CompanyModal,
    },
    emits: ['update-status', 'handleCompanyCheckbox'],
    setup(props) {
      const getTableConfig = () => {
        const settings = loadCellSettings(props.isPrecisionPicks);
        return getOrderedConfig(
          settings.cells.filter(cell => cell.enabled).map(cell => cell.key),
          companiesState.commonConfig
        );
      };
      
      const tableConfig = ref(getTableConfig());

      const tableContainer = shallowRef<HTMLElement | null>(null);
      const isDragging = ref(false);
      const startX = ref(0);
      const startY = ref(0);
      const scrollLeft = ref(0);
      const scrollTop = ref(0);

      const startDragging = (e: MouseEvent) => {
        if (!tableContainer.value || !(e.target as HTMLElement).closest('td')) return;
        isDragging.value = true;
        startX.value = e.pageX - tableContainer.value.offsetLeft;
        startY.value = e.pageY - tableContainer.value.offsetTop;
        scrollLeft.value = tableContainer.value.scrollLeft;
        scrollTop.value = tableContainer.value.scrollTop;
        tableContainer.value.style.cursor = 'grabbing';
        tableContainer.value.style.userSelect = 'none';
        document.body.style.userSelect = 'none';
      };

      const stopDragging = () => {
        isDragging.value = false;
        if (tableContainer.value) {
          tableContainer.value.style.cursor = 'grab';
          tableContainer.value.style.removeProperty('user-select');
        }
        document.body.style.removeProperty('user-select');
      };

      const drag = (e: MouseEvent) => {
        if (!isDragging.value || !tableContainer.value) return;
        e.preventDefault();
        const x = e.pageX - tableContainer.value.offsetLeft;
        const y = e.pageY - tableContainer.value.offsetTop;
        const walkX = (x - startX.value) * 2;
        const walkY = (y - startY.value) * 2;
        tableContainer.value.scrollLeft = scrollLeft.value - walkX;
        tableContainer.value.scrollTop = scrollTop.value - walkY;
      };

      const preventSelection = (e: Event) => {
        if (isDragging.value) {
          e.preventDefault();
        }
      };

      watch(() => props.listViewSettings, (newSettings) => {
        const settings = loadCellSettings(props.isPrecisionPicks);
        settings.cells = settings.cells.map(cell => ({
          ...cell,
          enabled: newSettings[cell.key] ?? cell.enabled
        }));
        saveCellSettings(settings, props.isPrecisionPicks);
        tableConfig.value = getTableConfig();
      }, { deep: true });
  
      watch(() => props.listViewSettingsOrder, (newOrder) => {
        const settings = loadCellSettings(props.isPrecisionPicks);
        settings.cells = newOrder.map(key => ({
          key,
          enabled: settings.cells.find(cell => cell.key === key)?.enabled ?? true
        }));
        saveCellSettings(settings, props.isPrecisionPicks);
        tableConfig.value = getTableConfig();
      });
  
      watch(() => props.isPrecisionPicks, (newValue) => {
        companiesState.updateCommonConfig(newValue, props.isActionsEnabled);
        tableConfig.value = getTableConfig();
      }, { immediate: true });

      watch(() => props.companies, () => {
        nextTick(() => {
          initializePopoversRef.value();
        });
      }, { deep: true });
  
      const initializePopovers = () => {
        const popovers = document.querySelectorAll('.popover-content');
        popovers.forEach(popover => {
          new Popover(popover, {
            placement: 'top',
            trigger: 'hover focus'
          });
        });
      };
  
      const initializePopoversRef = ref(initializePopovers);

      const disposePopovers = () => {
        const popovers = document.querySelectorAll('.popover-content');
        popovers.forEach(popover => {
          const bsPopover = Popover.getInstance(popover);
          if (bsPopover) {
            bsPopover.dispose();
          }
        });
      };

      onBeforeUnmount(() => {
        disposePopovers();
      });

      onUnmounted(() => {
        document.removeEventListener('selectstart', preventSelection);
      });
  
      onMounted(() => {
        document.addEventListener('selectstart', preventSelection);
        companiesState.updateCommonConfig(props.isPrecisionPicks, props.isActionsEnabled);
        nextTick(() => {
          initializePopoversRef.value();
        });
      });
  
      window.addEventListener('status-updated', () => {
        nextTick(() => {
          initializePopoversRef.value();
        });
      });
  
      return {
        tableConfig,
        initializePopoversRef,
        tableContainer,
        startDragging,
        stopDragging,
        drag,
        disposePopovers
      };
    },
    data() {
      return {
        selectedCompany: null
      };
    },
    updated() {
      nextTick(() => {
        this.initializePopoversRef();
      });
    },
    methods: {
      companyChecked(id, isChecked) {
        this.$emit('handleCompanyCheckbox', id, isChecked)
      },
      updateStatus( id, status, reason) {
        this.initializePopoversRef();
        this.$emit('update-status', id, status, reason);
      }
    }
  });
  </script>
  
  <style scoped>

  .limited-text {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  th:first-child, td:first-child{
      position: sticky;
      left: 0px;
      z-index: 1;
  }
  tr:nth-child(odd) > td{
      background-color: #fbfbfb;
  }
  .draggable-container {
      cursor: grab;
      max-height: 600px; /* Adjust as needed */
      overflow: auto;
  }
  .draggable-container:active {
      cursor: grabbing;
  }
  </style>

<style>
.row-passed {
  color: #e2e2e2 !important;
}
.row-passed .btn, .row-passed .badge, .row-passed a, .row-passed .progress {
  opacity: 0.5;
}
.clickable {
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.clickable:hover {
  opacity: 0.4;
}
</style>