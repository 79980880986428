<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing lead-info-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <div class="profile-info d-flex align-items-center" v-if="company_research_data && company_research_data.INFO">
        <template v-if="!company?.logo">
          <span class="d-flex justify-content-center align-items-center bg-primary rounded me-3 me-md-0"
            style="display: block; height: 85px; width: 85px">
            <i class="flaticon-search text-white p-2" style="font-size:50px; line-height: 1;"></i>
          </span>
        </template>
        <template v-else>
          <img :src="company.logo" class="rounded border p-2 me-3 me-md-0" alt="Logo" style="max-height: 85px; max-width: 85px" />
        </template>

        <div class="title mt-12 mt-sm-0">
          <h5 class="text-black fw-medium mb-8" v-if="company.name">{{ company.name }}</h5>
          <template v-else>
            <h5 class="placeholder-glow mb-8">
              <span class="placeholder col-12"></span>
            </h5>    
          </template>
          <div class="d-flex align-items-center">
            <span
                v-if="company.gap_closing_category && company.gap_closing_category.AVG.title"  @click="$emit('goToTab', 'gci', 'averaged_gap_analysis')"
                :class="{
              'badge rounded-pill fs-13 me-1 mb-1 cursor-pointer': true,
              'text-bg-secondary': company.gap_closing_category.AVG.title.toLowerCase().includes('neutral') == true,
              'text-bg-success': company.gap_closing_category.AVG.title.toLowerCase().includes('closing') == true,
              'text-bg-warning': company.gap_closing_category.AVG.title.toLowerCase().includes('widening') == true,
            }">
              {{ company.gap_closing_category.AVG.title }}
            </span>
            <template v-else>
              <span class="placeholder-glow"><span class="placeholder col-10 placeholder-lg"></span></span>
            </template>
          </div>
          <div class="d-flex mt-2 fs-md-20 text-muted" v-if="company_research_data && company_research_data.INFO">
            <a v-if="company_research_data.INFO.linkedin_url" :href="company_research_data.INFO.linkedin_url"
              target="_blank" class="mr-2 text-decoration-none">
              <i class="icon ph-bold ph-linkedin-logo"></i>
            </a>
            <a v-if="company_research_data.INFO.twitter_url" :href="company_research_data.INFO.twitter_url"
              target="_blank" class="mr-2 text-decoration-none">
              <i class="icon ph-fill ph-twitter-logo"></i>
            </a>
            <a v-if="company_research_data.INFO.facebook_url" :href="company_research_data.INFO.facebook_url"
              target="_blank" class="mr-2 text-decoration-none">
              <i class="icon ph-bold ph-facebook-logo"></i>
            </a>
          </div>
          <div v-else class="mt-2">
            <span class="placeholder-glow"><span class="placeholder col-6"></span></span>
          </div>
        </div>
      </div>
      <div v-else>
        <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
        <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
        <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
      </div>
      <template v-if="((company?.in_thesis != null && company.gap_closing_category.AVG.score) || (canAccessFeature('inboundList') && company?.status)) && company_research_data && company_research_data.INFO">

        <div class="border-top mt-5 mb-5 mt-sm-10 mb-sm-10 mt-md-15 mb-md-5 mt-lg-30 mb-lg-30"></div>

        <ul class="info mt-25 ps-0 mb-0 list-unstyled">
          <li class="position-relative" v-if="canAccessFeature('inboundList') && company?.status && company?.will_use_status_feature !== null">
          <div class="icon text-dark rounded-circle text-center">
            <i v-if="company?.will_use_status_feature" class="flaticon-map"></i>
            <i v-else class="flaticon-mail"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">
            {{ company?.will_use_status_feature ? 'Status' : 'Send Pass' }}
          </span>
          <UpdateActions
            ref="updateActionsRef"
            :initial-status="company.status"
            :status-reason="company.status_reason"
            :id="company.id"
            :use-status-feature="company.will_use_status_feature"
            @update-status="handleUpdateStatus"
            :select-class="'pt-2 pb-2'"
          />
          </li>
          <li class="position-relative" v-if="company?.in_thesis != null && company.gap_closing_category.AVG.score">
            <div class="icon text-black rounded-circle text-center">
              <i class="flaticon-taget"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Alignment Score</span>
            <AlignmentScore 
            :inThesis="company.in_thesis"
            :customAnswers="company.customAnswers"
            :gapClosingScore="company.gap_closing_category.AVG.score" 
            :reputationFlags="company.reputation_risks?.flags" 
            :softFailedFilters="company.thesis_failed_filters?.soft_fails" 
            :hardFailedFilters="company.thesis_failed_filters?.hard_fails" />
          </li>
          <li class="position-relative" v-if="canAccessFeature('inboundList') && company?.status == null">
          <div class="icon text-dark rounded-circle text-center">
            <i class="flaticon-mail"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">
            Draft a Pass Email
          </span>
          <button class="btn btn-outline-primary btn-sm" @click="passModalAction = true"  style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
            Click to Draft Pass Email
          </button>
          </li>
          <li class="position-relative" v-if="company?.in_thesis != null && company.gap_closing_category.AVG.score && company?.status">
            <div class="icon text-secondary rounded-circle text-center">
              <i class="flaticon-brainstorm"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Next Step Prediction</span>
            <PredictedOutcome
              :fullText="true"
              :inThesis="company.in_thesis"
              :gapClosingScore="company.gap_closing_category.AVG.score" 
              :status="company.status"
              :reputationFlags="company.reputation_risks?.flags" 
              :customAnswers="company?.customAnswers"
              :softFailedFilters="company.thesis_failed_filters?.soft_fails" 
              :hardFailedFilters="company.thesis_failed_filters?.hard_fails"
              :hasActiveFailures="company.has_active_failures"
              />
          </li>
          <li class="position-relative" v-if="company?.in_thesis != null && company.gap_closing_category.AVG.score">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-pen"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">1st Look Memo</span>
            <p>
              <button class="btn btn-sm btn-outline-primary" @click="memoModalAction = true" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">Click to Draft 1st Look Memo</button>
            </p>
          </li>
        </ul>
      </template>
      <div class="border-top mt-15 mb-15 mt-sm-20 mb-sm-20 mt-md-25 mb-md-25 mt-lg-30 mb-lg-30"></div>
        <div class="d-flex align-items-center justify-content-between">
          <h6 class="fw-medium text-black-emphasis mb-0">Company Information</h6>
        </div>
        <ul class="info mt-25 ps-0 mb-0 list-unstyled" v-if="company_research_data && company_research_data.INFO">
          <li class="position-relative"
            v-if="company.reputation_risks && company.reputation_risks.flags && company.reputation_risks.flags?.length > 0">
            <div class="icon text-warning rounded-circle text-center">
              <i class="flaticon-info"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold"> Reputation Risks </span>
            <template v-for="(flag, index) in company.reputation_risks.flags" :key="index">
              <span class="text-bg-warning badge me-1 mb-1 cursor-pointer" 
                    v-if="flagMap[flag]" 
                    @click="$emit('goToTab', 'company', flagMap[flag].report)">
                    {{ flagMap[flag].label }}
                </span>
            </template> 
          </li>
          <li class="position-relative"
            v-if="company_research_data && company_research_data.INFO && company_research_data.INFO.stage">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-industry"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Stage</span>
            <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
              {{ company_research_data.INFO.stage }}
              <template v-if="company_research_data.INFO.is_currently_raising">
                and is <span class="text-dark">Currently Raising</span>
              </template>
            </span>
          </li>
          <li class="position-relative"
            v-if="company_research_data.INFO && company_research_data.INFO.total_funding_raised != 0">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-money"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">
              Total Raised (per crunchbase)
            </span>
            <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
              {{ '$' + company_research_data.INFO.total_funding_raised.replace(/\.\d+/,
          '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
            </span>
          </li>
          <li class="position-relative"
            v-if="company.funding_rounds && company.funding_rounds.length > 0">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-money"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">
              Funding Rounds (per crunchbase)
            </span>
            <template v-for="(funding_round, index) in company.funding_rounds" :key="index">
                <template v-if="funding_round.funding_type">
                  {{funding_round.funding_type}}  
                  <template v-if="funding_round.announced_date">
                    ({{ new Date(funding_round.announced_date).toLocaleString('default', { month: 'long', year: 'numeric' }) }}):
                  </template>
                </template>
                <template v-if="funding_round.money_raised">
                  {{ '$' + funding_round.money_raised.replace(/\.\d+/,
                    '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }}
                </template>
                <template v-if="funding_round.number_of_investor"> 
                  <template v-if="funding_round.money_raised">
                    -
                  </template>
                  {{funding_round.number_of_investor}} Investors             
                </template> 
                <template v-if="index < company.funding_rounds.length - 1">
                  <br/>
                </template>
            </template> 
          </li>
          <li class="position-relative"
            v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.tags">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-status"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold"> Segments </span>
            <template v-for="(value, key) in company.raw_data[0].raw_json.tags" :key="key">
              <span class="badge rounded-pill text-outline-info me-1 mb-1" v-if="value">{{ key }}</span>
            </template>
          </li>
          <li class="position-relative"
            v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.sales_strategies">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-industry"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Business Model </span>
            {{ company.raw_data[0].raw_json.sales_strategies.business_type }} ({{
          company.raw_data[0].raw_json.sales_strategies.purchasers }})
          </li>
          <li class="position-relative"
            v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.product_types && hasActiveProductTypes">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-price-tag-1"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Product Type(s)</span>
            <template v-for="(value, key) in company.raw_data[0].raw_json.product_types" :key="key">
              <span class="badge rounded-pill text-outline-info me-1 mb-1" v-if="value">{{ key }}</span>
            </template>
          </li>
          <li class="position-relative"
              v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.revenue_models && hasActiveRevenueModels">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-credit-card"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Revenue Model(s)</span>
            <template v-for="(value, key) in company.raw_data[0].raw_json.revenue_models" :key="key">
              <span class="badge rounded-pill text-outline-info me-1 mb-1" v-if="value">{{ key }}</span>
            </template>
          </li>
          <li class="position-relative"
            v-if="company.raw_data && company.raw_data[0] && company.raw_data[0].raw_json && company.raw_data[0].raw_json.sales_strategies.target_audience">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-shopping-cart-2"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Target Audience </span>
            {{ company.raw_data[0].raw_json.sales_strategies.target_audience }}
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.founding_year">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-calendar"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Year Started</span>
            {{ company_research_data.INFO.founding_year }}
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.hq">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-maps-and-flags"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Head Quartered</span>
            <template v-if="company_research_data.INFO.hq.address">
            {{ company_research_data.INFO.hq.address }}, 
            </template>
            <template v-if="company_research_data.INFO.hq.city"> {{
          company_research_data.INFO.hq.city }}</template><template v-if="company_research_data.INFO.hq.state">, {{
          company_research_data.INFO.hq.state }}</template><template v-if="company_research_data.INFO.hq.country">,
              {{ company_research_data.INFO.hq.country }}</template>
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.contact_email">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-mail-inbox-app"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Email</span>
            <a :href="'mailto:' + company_research_data.INFO.contact_email"
              class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none">
              {{ company_research_data.INFO.contact_email }}
            </a>
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.contact_phone_number">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-telephone-call"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Phone No</span>
            <a :href="'tel:' + company_research_data.INFO.contact_phone_number"
              class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none">
              {{ company_research_data.INFO.contact_phone_number }}
            </a>
          </li>
          <li class="position-relative" v-if="company.website">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-web"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">Website</span>
            <a :href="company.website" target="_blank"
              class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none">
              {{ company.website }}
            </a>
          </li>
          <li class="position-relative" v-if="company_research_data.INFO.updated_at">
            <div class="icon text-dark rounded-circle text-center">
              <i class="flaticon-date-1"></i>
            </div>
            <span class="d-block text-black mb-5 fw-semibold">
              Report Updated
            </span>
            <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
              {{ formatTimeAgo( company_research_data.INFO.updated_at) }}
            </span>
          </li>
        </ul>
        <div v-else>
          <ul class="mt-25 ps-0 mb-0 list-unstyled">
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-6"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-10"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-8"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-8"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-11"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-5"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-6"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-9"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-10"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-7"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-12"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-5"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-10"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-7"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-9"></span></h5>
            </li>
            <li class="mb-25"> 
              <h3 class="placeholder-glow"><span class="placeholder col-7"></span></h3>
              <h5 class="placeholder-glow"><span class="placeholder col-10"></span></h5>
              <h5 class="placeholder-glow"><span class="placeholder col-9"></span></h5>
            </li>
          </ul>
        </div>
    </div>
  </div>
  <PassModal 
    v-if="company.id && company.name"
    v-model="passModalAction"
    :company-id="Number(company.id)"
    :company-name="company.name"
    :email-address="company.inbound_reply_to_email"
    :recipient-name="company.inbound_reply_to_name"
    :is-actions-enabled="isActionsEnabled"
    @update-status="updateCompanyStatus"
    />
    <MemoModal 
      v-if="company.id && company.name"
      v-model="memoModalAction"
      :company-id="Number(company.id)"
    />
</template>

<script lang="ts">
import { defineComponent, PropType, onMounted, onUpdated, ref, computed } from 'vue';
import { formatDistanceToNow } from 'date-fns';
import { canAccessFeature } from "@/utils/featuresUtils"
import { flagMap } from  '@/components/Reports/Companies/CompanyReportUtils';
import AlignmentScore from '@/components/Common/AlignmentScore.vue'
import PredictedOutcome from '@/components/Common/PredictedOutcome.vue'
import UpdateActions from '@/components/Common/UpdateActions.vue'
import PassModal from '@/components/Common/PassModal.vue'
import MemoModal from '@/components/Common/MemoModal.vue';
import { Popover } from 'bootstrap';

export default defineComponent({
  name: 'CompanyInformation',
  emits: ['goToTab', 'update-company', 'update-status'],
  props: {
    company: {
      type: Object,
      required: true,
    },
    company_research_data: {
      type: Object,
      required: true,
    },
  },
  components: {
    AlignmentScore,
    PredictedOutcome,
    UpdateActions,
    PassModal,
    MemoModal
  },
  computed: {
    flagMap() {
      return flagMap;
    },
    hasActiveRevenueModels(): boolean {
      if (this.company.raw_data && 
          this.company.raw_data[0] && 
          this.company.raw_data[0].raw_json && 
          this.company.raw_data[0].raw_json.revenue_models) {
        return Object.values(this.company.raw_data[0].raw_json.revenue_models).some(value => value === true);
      }
      return false;
    },
    hasActiveProductTypes(): boolean {
      if (this.company.raw_data && 
          this.company.raw_data[0] && 
          this.company.raw_data[0].raw_json && 
          this.company.raw_data[0].raw_json.product_types) {
        return Object.values(this.company.raw_data[0].raw_json.product_types).some(value => value === true);
      }
      return false;
    }
  },
  setup(props, { emit }) {

    const passModalAction = ref(false);
    const memoModalAction = ref(false);
    const updateActionsRef = ref<InstanceType<typeof UpdateActions> | null>(null);

    const updateCompanyStatus = async (id: string | number, status: string, reason: string | boolean = false, send = false) => {

      if (!id || !status) return;

      if (status === 'Passed' && (reason === false || reason === null)) {
        passModalAction.value = true;
        return;
      }

      // IF company doesn't have status, then don't update it for not being inbound!
      if ( !props.company?.status ) return;

      if (status === 'Passed' && reason) initializePopovers();

      emit('update-company',  {
        id,
        status: status,
        status_reason: reason
      });

    };

    const handleUpdateStatus = ( id, status, reason ) => {
      updateCompanyStatus(id, status, reason);
    };

    const initializePopovers = () => {
      const popovers = document.querySelectorAll('.popover-content');
      popovers.forEach(popover => {
        new Popover(popover, {
          placement: 'top',
          trigger: 'hover focus'
        });
      });
    };

    onMounted(() => {
      initializePopovers();
    });

    onUpdated(() => {
      initializePopovers();
    });

    const initializePopoversRef = ref(initializePopovers);

    const formatTimeAgo = (dateString) => {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    };

    const isActionsEnabled = computed(() => {
      if (!props.company?.status) {
        return false;
      }
      return props.company.will_use_status_feature;
    });

    return {
      formatTimeAgo,
      canAccessFeature,
      initializePopoversRef,
      passModalAction,
      memoModalAction,
      handleUpdateStatus,
      updateCompanyStatus,
      updateActionsRef,
      isActionsEnabled
    };
  }
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>