<template>
    <b-modal v-model="isOpen"
    centered :title="!streamedData && !isDrafting ? 'Reason for Passing' : (isDrafting && !streamedData ? 'Writing draft...' : 'Your draft')" hide-footer :dialog-class="streamedData ? 'modal-md' : 'modal-sm'" @hide="close">
      <div class="d-block">
        <div class="btn-group-vertical" role="group" style="width: 100%" v-if="!streamedData && !isDrafting">
          <template v-for="reason in passReasons" :key="reason.id">
            <input type="radio" class="btn-check" name="btnradio" :id="reason.id" autocomplete="off" 
                   v-model="selectedID" :value="reason.id">
            <label class="btn btn-outline-primary" :for="reason.id" @click="selectedID = reason.id">
              {{ reason.label }}
            </label>
          </template>
        </div>

        <div v-if="isDrafting && !streamedData">
          <div class="d-flex flex-column align-items-center justify-content-center mb-3">
            <div class="spinner-border mt-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>

        <div class="position-relative" v-if="streamedData && isDrafting">
          <h6 class="mb-1 fs-16">Customized follow-up{{ companyName ? ` for ${companyName}` : '' }}:</h6>
          <textarea v-model="streamedData" readonly style="display:none"></textarea>
          <div class="mt-3 text-bg-light p-3 fs-md-13 fs-lg-14">
            {{ recipientName ? `Hi ${recipientName},` : 'Hi,' }}<br/><br/>
            <div v-html="streamedData.replace(/\n/g, '<br>')"></div>
          </div>
          <button @click="copyToClipboard" class="btn btn-sm btn-secondary position-absolute bottom-0 end-0 mb-1 me-1">
            <i class="flaticon-copy"></i> 
          </button>
        </div>
      </div>
  
      <div class="d-flex justify-content-between align-items-center">
      
        <template v-if="!streamedData && !isDrafting">
          <template v-if="isActionsEnabled">
            <b-button class="mt-3 btn btn-secondary btn-sm" @click="save">Save</b-button>
            <b-button class="mt-3 btn btn-primary btn-sm" @click="saveDraft">Save & Draft Email</b-button>
          </template>
          <template v-else>
            <b-button class="mt-3 btn btn-primary btn-sm w-100" @click="saveDraft">Draft Pass Email</b-button>
          </template>
          
        </template>

        <template v-if="streamedData">
          <div class="form-check mt-3">
            <input class="form-check-input" type="checkbox" v-model="alwaysOpenInClient" id="alwaysOpenInClient">
            <label class="form-check-label" for="alwaysOpenInClient">
              Auto Open {{ loginType === 'gsuite' ? 'Gmail' : 'Email App' }}
            </label>
          </div>
          <b-button class="mt-3 btn btn-primary btn-sm" @click="openEmail">Open in {{ loginType === 'gsuite' ? 'Gmail' : 'Email App' }}</b-button>
        </template>
      </div>
    </b-modal>

  </template>
  
  <script lang="ts">
  import { defineComponent, ref, watch, onMounted, PropType } from 'vue';
  import * as followupService from '@/api/followupService';
  import { statusService } from '@/api/statusService';
  import Popover from 'bootstrap/js/dist/popover';
  import { StreamResponse } from '@/utils/apiUtils';

  export default defineComponent({
    name: 'PassModal',
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
      companyId: {
        type: Number,
        required: true,
      },
      companyName: {
        type: String,
        required: false,
      },
      emailAddress: {
        type: String,
        required: false,
      },
      recipientName: {
        type: String,
        required: false,
      },
      isActionsEnabled: {
        type: Boolean as PropType<boolean | null>,
        default: null,
      }
    },
    emits: ['update:modelValue', 'update-status'],
    setup(props, { emit }) {
      const isOpen = ref(props.modelValue);
      const streamedData = ref('');
      const selectedID = ref('');
      const alwaysOpenInClient = ref(false);
      const isDrafting = ref(false);
      const loginType = ref(localStorage.getItem('login_type') || null);

      const passReasons = [
        { id: 'LOT', value: 'Lack of Traction', label: 'Lack of Traction' },
        { id: 'TS', value: 'Too Soon', label: 'Too Soon' },
        { id: 'NVCS', value: 'Not VC Scaleable', label: 'Not VC Scaleable' },
        { id: 'NFVCF', value: 'Not for VC Funding', label: 'Not for VC Funding' },
        { id: 'NGC', value: 'Not Gap Closing', label: 'Not Gap Closing' },
        { id: 'NWG', value: 'Not within Geography', label: 'Not within Geography' },
        { id: 'NIA', value: 'Not Industry Aligned', label: 'Not Industry Aligned' },
        { id: 'NWS', value: 'Not within Stage', label: 'Not within Stage' },
      ];
  
      watch(() => props.modelValue, (newValue) => {
        isOpen.value = newValue;
      });
  
      watch(isOpen, (newValue) => {
        emit('update:modelValue', newValue);
      });

      watch(alwaysOpenInClient, (newValue) => {
        localStorage.setItem('alwaysOpenInClient', JSON.stringify(newValue));
      });

      onMounted(() => {
        const savedPreference = localStorage.getItem('alwaysOpenInClient');
        if (savedPreference !== null) {
          alwaysOpenInClient.value = JSON.parse(savedPreference);
        }
      });

      const openEmail = () => {

        const companyName = props.companyName || '';

        const email = props.emailAddress || '';

        const subject = `Follow-up To Your Pitch${companyName ? ` for ${encodeURIComponent(companyName)}` : ''}`;

        const greetings = props.recipientName ? `Hi ${props.recipientName},%0A%0A` : 'Hi,%0A%0A';
        const body = greetings + encodeURIComponent(streamedData.value);

        let url;
        if (loginType.value === 'gsuite') {
          url = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
        } else {
          url = `mailto:${email}?subject=${subject}&body=${body}`;
        }

        window.open(url, '_blank');
        
      };
  
      const close = () => {
        isOpen.value = false;
        selectedID.value = '';
        isDrafting.value = false;
        streamedData.value = '';
      };
  
      const  updateStatus = async (isDraft: boolean) => {

        const selectedReason = passReasons.find(reason => reason.id === selectedID.value)?.value || '';

        try {
          const response = await statusService.updateCompaniesStatus([props.companyId], 'PASD', selectedReason);
        } catch (error) {
          console.error('Error updating status:', error);
        }

        emit('update-status', props.companyId, 'Passed', selectedReason);

        if (isDraft) {

          isDrafting.value = true;

          try {
            const stream: StreamResponse = await followupService.generateFollowup(props.companyId, selectedID.value);

            for await (const chunk of stream) {
              if ( !isDrafting.value) {
                break;
              }
              streamedData.value += chunk;
            }

            if (alwaysOpenInClient.value && isDrafting.value) {
              openEmail();
            }

          } catch (error) {
            console.error('Error generating followup:', error);
          }
        
        } else {
          close();
        }
       
      };

      const copyToClipboard = () => {

        const greetings = props.recipientName ? `Hi ${props.recipientName},\n\n` : 'Hi,\n\n';

        navigator.clipboard.writeText(greetings + streamedData.value)
          .then(() => {
          alert('Copied to clipboard!');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    };
  
      return {
        isOpen,
        selectedID,
        passReasons,
        close,
        streamedData,
        alwaysOpenInClient,
        openEmail,
        copyToClipboard,
        isDrafting,
        loginType,
        saveDraft: () => updateStatus(true),
        save: () => updateStatus(false),
      };
    },
  });
  </script>