<template>
  <div class="row">

    <div v-for="company in companies" :key="company.id" class="col-md-12 col-xxl-6 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing project-card">
        <div class="card-body p-20 p-md-25 p-lg-30">
          <div class="mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center">
            <div class="title d-flex align-items-center">
              <router-link :to="'/company-report/'+ company.id">
                <img :src="company.logo ? company.logo : require('@/assets/images/social/social8.jpg')" class="rounded-1 border p-2 img-thumbnail" width="60" alt="project" />
              </router-link>
              <div class="ms-15">
                <h5 class="mb-1 fs-14 fs-md-16 fs-lg-18 fw-bold mt-8 mt-md-0">
                  <router-link :to="'/company-report/'+ company.id" class="text-decoration-none text-black">
                    {{ company.name }}
                  </router-link>
                  <template v-if="company.stage">
                    <span class="text-secondary fw-light">: {{ company.stage }}</span>
                  </template>
                </h5>
                
                <CompanyAlignment :company="company" />

                <CompanyStatus :company="company" />
              </div>
            </div>
            <div class="dropdown">
              <router-link :to="'/company-report/'+ company.id">
                <button class="btn btn-primary btn-sm me-15" type="button">
                  View Research
                </button>
              </router-link>
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button" data-bs-toggle="dropdown" aria-expanded="false" v-if="canAccessFeature('favorites') || canAccessFeature('customLists') ||canAccessFeature('companyActions')">
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>

                  <router-link :to="'/company-report/'+ company.id" class="dropdown-item d-flex align-items-center">
                    <i
                      class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                      View Report
                  </router-link>
                </li>
                <li v-if="canAccessFeature('favorites')">
                  <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"><i
                      class="flaticon-heart lh-1 me-8 position-relative top-1"></i>
                    Favorite</a>
                </li>
                <li v-if="canAccessFeature('customLists')">
                  <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"><i
                      class="flaticon-plus lh-1 me-8 position-relative top-1"></i>
                    Add to list</a>
                </li>
                <li v-if="canAccessFeature('companyActions')">
                  <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"><i
                      class="flaticon-close lh-1 me-8 position-relative top-1"></i>
                    Pass</a>
                </li>
              </ul>
            </div>
          </div>

          <p class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-13 fs-lg-14" v-if="company.description">
            {{ company.description }}
          </p>
          
          <p class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-13 fs-lg-14" v-if="company.raised">
            Raised to Date (Per Crunchbase) <span class="text-primary"><strong>{{ company.raised }}</strong></span>
          </p>

          <p class="mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-13 fs-lg-14" v-if="company.founding_year || company.location_country_HQ">
            <template v-if="company.founding_year">Founded in {{ company.founding_year }}.</template> 
            <template v-if="company.location_country_HQ"> Located in {{ company.location_country_HQ }}.</template> 
          </p>

          <CompanyDescriptors :company="company" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from 'vue-router';
import CompanyAlignment from './Cells/CompanyAlignmentCell.vue'
import CompanyDescriptors from './Cells/CompanyDescriptorsCell.vue'
import { canAccessFeature } from '@/utils/featuresUtils'

export default defineComponent({
  name: "CompaniesGridView",
  setup() {
    return {
      canAccessFeature
    }
  },
  props: {
    appliedFilters: {
      type: Array,
      default: () => []
    },
    companies: {
      type: Array,
      required: true
    }
  },
  components: {
    CompanyDescriptors,
    CompanyAlignment
    },
});
</script>