<template>
    <div :class="`card mb-25 rounded-0 bg-white letter-spacing ${global_tag == content_id ? 'border border-1 border-info' : 'border-0'}`" v-if="content && content_id && title" :id="content_id">
      <div class="card-head bg-white d-md-flex align-items-center justify-content-between pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
        <h5 class="card-title fw-bold mb-0">{{ title }}</h5>
      </div>
      <div class="card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30"
        v-html="processedContent"
        v-add-links="{ content: processedContent, emit: $emit }">
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, computed } from 'vue';
  
  export default defineComponent({
    props: {
      title: String,
      content: String,
      content_id: String,
      global_tag: String
    },
  
    emits: ['goToTab'],
  
    setup(props) {
      const linkMappings = {
        'Core Product(s):': ['company', 'report-PROD'],
        'Key Differentiators': ['company', 'report-PROD'],
        'Pricing:': ['company', 'report-PRODCOST'],
        'Purchasers:': ['company', 'report-TARAUD'],
        'Partnerships:': ['company', 'report-NEWS'],
        'Latest News Headlines:': ['company', 'report-NEWS'],
      };
  
      const processedContent = computed(() => {
        if (!props.content) return '';
  
        const parser = new DOMParser();
        const doc = parser.parseFromString(props.content, 'text/html');
  
        // Find all ul elements
        doc.querySelectorAll('ul').forEach(ul => {
            const listItems = Array.from(ul.querySelectorAll('li'));
            let foundCoreProducts = false;
            const itemsToHide: HTMLLIElement[] = [];
  
            // First pass: identify items to hide
            listItems.forEach((li: HTMLLIElement) => {
                if (li.textContent?.includes('Core Product(s):')) {
                    foundCoreProducts = true;
                } else if (!foundCoreProducts) {
                    itemsToHide.push(li);
                }
            });
  
            // Hide the identified items
            itemsToHide.forEach((li: HTMLLIElement) => {
                li.style.display = 'none';
            });
        });
  
        // Original link mapping logic
        doc.querySelectorAll('li').forEach(li => {
            for (const [key, [tabName, sectionId]] of Object.entries(linkMappings)) {
                if (li.textContent?.startsWith(key)) {
                    const link = doc.createElement('a');
                    link.href = '#';
                    link.textContent = key;
                    link.dataset.tabName = tabName;
                    link.dataset.sectionId = sectionId;
                    link.classList.add('go-to-tab-link');
                    li.innerHTML = li.innerHTML.replace(key, link.outerHTML);
                    break;
                }
            }
        });
  
        return doc.body.innerHTML;
      });
  
      return {
        processedContent
      };
    },
  
    directives: {
      addLinks: {
        mounted(el, binding) {
          addEventListeners(el, binding);
        },
        updated(el, binding) {
          // Re-add event listeners when content updates
          addEventListeners(el, binding);
        }
      }
    }
  });

  // Define addEventListeners function outside of the component
  function addEventListeners(el: HTMLElement, binding: any) {
    const { emit } = binding.value;
    el.querySelectorAll('.go-to-tab-link').forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const tabName = (link as HTMLElement).dataset.tabName;
        const sectionId = (link as HTMLElement).dataset.sectionId;
        emit('goToTab', tabName, sectionId);
      });
    });
  }
  </script>