import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "card-head bg-white d-md-flex align-items-center justify-content-between pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" }
const _hoisted_3 = { class: "card-title fw-bold mb-0" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_add_links = _resolveDirective("add-links")!

  return (_ctx.content && _ctx.content_id && _ctx.title)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`card mb-25 rounded-0 bg-white letter-spacing ${_ctx.global_tag == _ctx.content_id ? 'border border-1 border-info' : 'border-0'}`),
        id: _ctx.content_id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.title), 1)
        ]),
        _withDirectives(_createElementVNode("div", {
          class: "card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30",
          innerHTML: _ctx.processedContent
        }, null, 8, _hoisted_4), [
          [_directive_add_links, { content: _ctx.processedContent, emit: _ctx.$emit }]
        ])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}