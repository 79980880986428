<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Reset Password</h4>
          <form @submit.prevent="handleSubmit" v-if="isTokenValid">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10" for="password">
                New Password
              </label>
              <input
                id="password"
                v-model="password"
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="Enter your new password"
                required
              />
            </div>
            <div class="form-group mb-15 mb-sm-20">
              <label class="d-block text-black fw-semibold mb-10" for="confirmPassword">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                v-model="confirmPassword"
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="Confirm your new password"
                required
              />
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? 'Resetting...' : 'Reset Password' }}
            </button>
            <div v-if="message" class="mt-3 text-center" :class="{'text-success': !error, 'text-danger': error}">
              {{ message }}
            </div>
          </form>
          <div v-else class="text-center text-danger">
            Invalid or expired reset token. Please request a new password reset.
          </div>
          <div class="text-center mt-15 mt-sm-20 mt-md-25">
            <router-link
              to="/login"
              class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
            >
              <i class="ph-bold ph-arrow-left"></i>
              Back To Sign In
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LegalLinks />
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { userService } from '@/api/userService';
import LegalLinks from '@/components/Common/LegalLinks.vue'
export default {
  name: "ResetPassword",
  components: {
    LegalLinks
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const password = ref('');
    const confirmPassword = ref('');
    const isSubmitting = ref(false);
    const message = ref('');
    const error = ref(false);
    const isTokenValid = ref(false);

    const uid = route.params.uid;
    const token = route.params.token;
    const timestamp = route.params.timestamp;

    onMounted(async () => {
      try {
        await userService.passwordResetVerify(uid, token, timestamp);
        isTokenValid.value = true;
      } catch (err) {
        console.error('Token verification failed:', err);
        isTokenValid.value = false;
      }
    });

    const handleSubmit = async () => {
      if (password.value !== confirmPassword.value) {
        error.value = true;
        message.value = 'Passwords do not match.';
        return;
      }

      isSubmitting.value = true;
      message.value = '';
      error.value = false;

      try {
        await userService.passwordSet(uid, token, timestamp, password.value);
        message.value = 'Password has been reset successfully.';
        setTimeout(() => router.push('/login'), 3000); // Redirect to login after 3 seconds
      } catch (err) {
        error.value = true;
        message.value = 'An error occurred. Please try again later.';
        console.error('Password reset error:', err);
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      password,
      confirmPassword,
      isSubmitting,
      message,
      error,
      isTokenValid,
      handleSubmit
    };
  }
};
</script>