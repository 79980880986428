<template>
  <PredictedOutcome 
    :hardFailedFilters="company.thesis_failed_filters?.hard_fails || []"
    :softFailedFilters="company.thesis_failed_filters?.soft_fails || []"
    :customAnswers="company?.custom_answers"
    :gapClosingScore="company.gap_closing_score"
    :status="company.status"
    :inThesis="company.in_thesis"
    :hasActiveFailures="company.has_active_failures"
  />
</template>

<script>
import { companiesEmits } from '@/components/Companies/CompaniesUtils.js'
import PredictedOutcome from '@/components/Common/PredictedOutcome.vue'

export default {
  props: ['company'],
  emits: companiesEmits,
  components: {
    PredictedOutcome
  }
}
</script>