export const getProgressBackgroundClass = (alignment = false, score = 0, gapClosingScore = 0) => {
    return {
        'progress-bar': true,
        'bg-success': alignment === true && score >= 70,
        'bg-warning': (alignment === true && score < 70 && score >= 50) || (alignment === true && gapClosingScore >= 6 && gapClosingScore < 7),
        'bg-danger': alignment === false || (alignment === true && score < 50)
    };
};

export const getDescriptorBadgeClass = (alignment = false, score = 0) => {
    return {
        'badge fs-13 me-1': true,
        'text-outline-success': alignment === true && score >= 7,
        'text-outline-warning': alignment === true && score < 7 && score >= 6,
        'text-outline-danger': alignment === false || (alignment === true && score < 6)
    };
};
