<template>
    <div :class="`card mb-25 rounded-0 bg-white letter-spacing ${global_tag == content_id ? 'border border-1 border-info' : 'border-0'}`" v-if="content && flags?.length > 0" :id="content_id">
        <div
            class="card-head bg-white d-md-flex align-items-center pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <h5 class="card-title fw-bold mb-0">{{ title }}</h5>
            <template v-for="(flag, index) in flags" :key="index">
                <span class="text-bg-warning badge fs-13 ms-2 cursor-pointer" 
                    v-if="flagMap[flag]" 
                    @click="$emit('goToTab', 'company', flagMap[flag].report)">
                    {{ flagMap[flag].label }}
                </span>
            </template>
        </div>
        <div class="card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <template v-for="(flag, index) in flags" :key="index">
                <p v-if="flagMap[flag]" @click="$emit('goToTab', 'company', flagMap[flag].report)" class="text-primary cursor-pointer">
                    Click here to read details about the {{ flagMap[flag].label.toLowerCase() }}.
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import { flagMap } from  '@/components/Reports/Companies/CompanyReportUtils';

export default {
    props: ['title', 'content', 'content_id', 'global_tag', 'flags'],
    emits: ['goToTab'],
    computed: {
        flagMap() {
            return flagMap;
        }
    }
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>