import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateActions = _resolveComponent("UpdateActions")!

  return (_openBlock(), _createBlock(_component_UpdateActions, {
    "initial-status": _ctx.company.status,
    "status-reason": _ctx.company.status_reason,
    id: _ctx.company.id,
    "use-status-feature": _ctx.company.will_use_status_feature,
    onUpdateStatus: _ctx.handleUpdateStatus
  }, null, 8, ["initial-status", "status-reason", "id", "use-status-feature", "onUpdateStatus"]))
}