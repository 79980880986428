<template>
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
        <div
            :id="'toastNotification'+id"
            class="toast hide"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-autohide="false"
        >
            <div class="toast-header">
                <svg
                    class="rounded me-2"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                >
                    <rect width="100%" height="100%" fill="#726eed"></rect>
                </svg>
                <strong class="me-auto text-black">{{ title }}</strong>
                <button
                    type="button"
                    class="btn-close shadow-none"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                ></button>
            </div>
            <div class="toast-body fs-md-15 fs-lg-16 text-paragraph lh-base">
                {{ displayedMessage }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            displayedMessage: 'Default message',
            displayedTitle: 'Notification',
            toastTimeoutId: null,
        };
    },
    props: {
        message: {
            type: String,
            default: 'Default message',
        },
        title: {
            type: String,
            default: 'Notification',
        },
        id: {
            type: String,
            default: '',
        }
    },
    methods: {
        showToast() {
            // Hide the toast first if it's already displayed
            this.hideToast();
            
            //  Get the toast element and create a new toast instance
            const toastElement = document.getElementById('toastNotification' + this.id);
            const toastBootstrap = new window.bootstrap.Toast(toastElement);
            
            // Wait a bit for the hide animation to complete before showing a new toast
            setTimeout(() => {
                this.displayedMessage = this.message;
                this.displayedTitle = this.title;
                toastBootstrap.show();
            }, 200);

            // Set a timeout to hide the toast after 10 seconds
            this.toastTimeoutId = setTimeout(() => {
                toastBootstrap.hide();
            }, 6000);
        },
        hideToast() {
            const toastElement = document.getElementById('toastNotification' + this.id);

            if (toastElement.classList.contains('show')) {
                const toastBootstrap = new window.bootstrap.Toast(toastElement);
                toastBootstrap.hide();
            }
            
            // Also clear the timeout when manually hiding the toast
            if (this.toastTimeoutId) {
                clearTimeout(this.toastTimeoutId);
            }
        }
    },
    mounted() {
        if (typeof window.bootstrap === 'undefined') {
            const script = document.createElement('script');
            script.src = "https://cdn.jsdelivr.net/npm/bootstrap@5/dist/js/bootstrap.bundle.min.js";
            document.head.appendChild(script);
        }
    },
}
</script>