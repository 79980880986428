<template>
    <WidgetHeader />
  
    <div class="row">
        <div class="col-xxl-4">
            <WidgetPrecisionPicks />
        </div>
        <div class="col-xxl-8">
            <WidgetInboundGraph />
        </div>
        </div>
</template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import { useRouter } from 'vue-router';
  import { canAccessFeature } from '@/utils/featuresUtils';
  import { preserveParams } from '@/utils/globalRouter';
  
  import WidgetHeader from "../../components/Dashboard/WidgetHeader.vue";
  import WidgetInboundGraph from "../../components/Dashboard/WidgetInboundGraph.vue";
  import WidgetPrecisionPicks from "../../components/Dashboard/WidgetPrecisionPicks.vue";
  
  export default defineComponent({
    name: "DashboardPage",
    components: {
      WidgetHeader,
      WidgetInboundGraph,
      WidgetPrecisionPicks
    },
    beforeRouteEnter(to, from, next) {
      if (!canAccessFeature('dashboard')) {
        next({ name: 'SearchPage', query: { ...preserveParams(to), ...preserveParams(from) } }); 
      } else {
        next();
      }
    }
  });
  </script>