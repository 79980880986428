<template>
    <AlignmentScore 
    :status="company.status"
    :inThesis="company.in_thesis"
    :gapClosingScore="company.gap_closing_score" 
    :customAnswers="company?.custom_answers"
    :reputationFlags="company.reputation_risks?.flags" 
    :softFailedFilters="company.thesis_failed_filters?.soft_fails" 
    :hardFailedFilters="company.thesis_failed_filters?.hard_fails" 
    :submission="company?.submission"
    :hasActiveFailures="company.has_active_failures"
    />
</template>
  
  <script>
  import AlignmentScore from '@/components/Common/AlignmentScore.vue'
  import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'
  
  export default {
    props: ['company'],
    emits: companiesEmits,
    components: {
        AlignmentScore
    }
  };
  </script>