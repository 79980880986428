<template>
    <div class="text-center mt-3">
      <router-link to="/privacy" class="text-primary fw-bold text-decoration-none">Privacy Policy</router-link>
      |
      <router-link to="/terms" class="text-primary fw-bold text-decoration-none">Terms of Service</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LegalLinks'
  }
  </script>
  
  <style scoped>
  .text-primary {
    color: #007bff;
  }
  
  .fw-bold {
    font-weight: bold;
  }
  
  .text-decoration-none {
    text-decoration: none;
  }
  
  .text-center {
    text-align: center;
  }
  
  .mt-3 {
    margin-top: 1rem;
  }
  </style>