import { reactive } from 'vue';
import { canAccessFeature } from '@/utils/featuresUtils'; 

export interface ConfigItem {
  header: string;
  key: string;
  component: string;
  class?: string;
  cellClass?: string;
  filterable?: boolean;
}

interface CellSettings {
  version: number;
  cells: {
    key: string;
    enabled: boolean;
  }[];
}

const CURRENT_VERSION = 7;

export function loadCellSettings(isPrecisionPicks: boolean): CellSettings {
  const storageKey = isPrecisionPicks ? 'cellSettingsPrecisionPicks' : 'cellSettingsStandard';
  const saved = localStorage.getItem(storageKey);
  
  if (saved) {
    const parsedSettings = JSON.parse(saved) as CellSettings;
    if (parsedSettings.version === CURRENT_VERSION) {
      return parsedSettings;
    }
    // If version doesn't match, we'll fall through to return default settings
  }
  
  return getDefaultSettings(isPrecisionPicks);
}

export function getDefaultSettings(isPrecisionPicks: boolean): CellSettings {
  const defaultOrder = isPrecisionPicks ? [
    'name', 'stage', 'founding_year', 'location',
    'total_funding_raised', 'description', 'descriptors', 'actions'
  ] : [
    'name', 'submission_date', 'submission', 'alignment_score', 'predicted_outcome', 'actions', 'reputation_risks', 'stage', 'founding_year', 'location', 'total_funding_raised', 'description', 'descriptors' 
  ];

  return {
    version: CURRENT_VERSION,
    cells: defaultOrder.map(key => ({ key, enabled: true }))
  };
}

export function saveCellSettings(settings: CellSettings, isPrecisionPicks: boolean) {
  const storageKey = isPrecisionPicks ? 'cellSettingsPrecisionPicks' : 'cellSettingsStandard';
  localStorage.setItem(storageKey, JSON.stringify(settings));
}

export const companiesState = reactive({
  cellSettings: loadCellSettings(false),
  commonConfig: [] as ConfigItem[],

  updateCommonConfig(isPrecisionPicks: boolean, isActionsEnabled: boolean) {
    let baseConfig: ConfigItem[] = [
      { header: 'Company', key: 'name', component: 'CompanyName', class: 'ps-0', cellClass: 'ps-0', filterable: false },
      { header: 'Alignment', key: 'alignment', component: 'CompanyAlignment', class: '', cellClass: '', filterable: true},
      { header: 'Feedback', key: 'feedback', component: 'CompanyFeedback', class: 'text-center', cellClass: 'text-center', filterable: false },
      { header: 'Overview', key: 'description', component: 'CompanyDescription', class: '', cellClass: '', filterable: false},
      { header: 'Stage', key: 'stage', component: 'CompanyText', class: '', cellClass: '', filterable: true },
      { header: 'Founding Year', key: 'founding_year', component: 'CompanyText', class: 'text-center', cellClass: 'text-center', filterable: false },
      { header: 'Country', key: 'location', component: 'CompanyLocation', class: 'text-center', cellClass: 'text-center', filterable: false },
      { header: 'Descriptors', key: 'descriptors', component: 'CompanyDescriptors', class: '', cellClass: '', filterable: true },
      { header: 'Raised', key: 'total_funding_raised', component: 'CompanyText', class: '', cellClass: '', filterable: false },
      { header: isActionsEnabled ? 'Change Status' : 'Actions', key: 'actions', component: 'CompanyActions', class: '', cellClass: '', filterable: false  }
    ];

    if (!isPrecisionPicks) {
      baseConfig.push(
        { header: 'Reputation Alerts', key: 'reputation_risks', component: 'CompanyReputationRisks', class: 'text-center', cellClass: 'text-center', filterable: false },
        { header: 'Alignment Score', key: 'alignment_score', component: 'CompanyAlignmentScore', class: '', cellClass: '', filterable: true},
        { header: 'Predicted Outcome', key: 'predicted_outcome', component: 'CompanyPredictedOutcome', class: '', cellClass: '', filterable: true},
        { header: 'Submission', key: 'submission', component: 'CompanySubmission', class: 'text-center', cellClass: 'text-center', filterable: false },
        { header: 'Submission Date', key: 'submission_date', component: 'CompanySubmissionDateCell', class: 'text-center', cellClass: 'text-center', filterable: true },
        { header: 'Status', key: 'status', component: 'CompanyStatus', class: '', cellClass: '', filterable: true }
      );
    }

    if (!canAccessFeature('inboundListActions')) {
      baseConfig = baseConfig.filter(config => config.key !== 'actions');
      baseConfig = baseConfig.filter(config => config.key !== 'options');
    }

    if (!canAccessFeature('listFeedback')) {
      baseConfig = baseConfig.filter(config => config.key !== 'feedback');
    }

    this.commonConfig = baseConfig;
    this.cellSettings = loadCellSettings(isPrecisionPicks);

    return baseConfig;
  }
});

export function findCommonElements(array1: any[], array2: any[]): any[] {
  if (typeof array1 === 'object' && array1 !== null) {
    array2 = Object.values(array2);
  }
  if (typeof array1 === 'object' && array1 !== null) {
    array2 = Object.values(array1);
  } 
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    console.error('findCommonElements: Both arguments should be arrays', { array1, array2 });
    throw new Error('Both arguments should be arrays');
  }
  return array1.filter(element => array2.includes(element));
}

export function findNotCommonElements(array1: any[], array2: any[]): any[] {
  if (typeof array1 === 'object' && array1 !== null) {
    array2 = Object.values(array2);
  }
  if (typeof array1 === 'object' && array1 !== null) {
    array2 = Object.values(array1);
  } 
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    console.error('findCommonElements: Both arguments should be arrays', { array1, array2 });
    throw new Error('Both arguments should be arrays');
  }
  return array1.filter(element => !array2.includes(element));
}

export function getOrderedConfig(cellOrder: string[], commonConfig: ConfigItem[]): ConfigItem[] {
  return cellOrder.map(key => commonConfig.find(config => config.key === key)).filter(Boolean) as ConfigItem[];
}