<template>
    <div class="carousel-container" :class="{ 'fullscreen': isFullscreen }" @click="handleOutsideClick">
        <div :id="carouselId" class="carousel carousel-fade slide" @click.stop>
            <div class="carousel-indicators" :class="{ 'is-visible': showIndicators }">
                <button v-for="(slide, index) in slides" :key="index" type="button" :data-bs-target="'#' + carouselId"
                        :data-bs-slide-to="index" :class="{ active: index === 0 }"
                        :aria-label="'Slide ' + (index + 1)"></button>
            </div>
            <div class="carousel-inner">
                <div v-for="(slide, index) in slides" :key="index" class="carousel-item" :class="{ active: index === 0 }">
                    <div v-if="isHtml" class="html-content">
                        <div v-html="slide"></div>
                    </div>
                    <div v-else>
                        <img :src="slide" alt="slide-image" />
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" :data-bs-target="'#' + carouselId"
                    data-bs-slide="prev" v-if="showNavigation">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" :data-bs-target="'#' + carouselId"
                    data-bs-slide="next" v-if="showNavigation">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        <div class="preview-container" ref="previewContainer">
            <div v-for="(slide, index) in slides" :key="index" 
                 class="preview-slide" 
                 :class="{ 'active': index === activeIndex }"
                 @click="goToSlide(index, false)"
                 :ref="el => { if (el) previewSlides[index] = el }">
                <img v-if="!isHtml" :src="slide" alt="preview-image" />
                <div v-else class="html-preview" v-html="slide"></div>
            </div>
        </div>
        <button v-if="!isHtml" @click.stop="toggleFullscreen" class="fullscreen-toggle">
        <span v-if="!isFullscreen">⛶</span>
        <span v-else>
            <button type="button" class="btn-close shadow-none btn-close-white btn btn-lg" aria-label="Close"></button>
        </span>
        </button>
    </div>
    <ToastNotification :message="toastMessage" :ref="'toastComponent' + carouselId" :id="carouselId" :title="toastTitle"/>
</template>

<script>
import { ref, onMounted } from 'vue';
import ToastNotification from "@/components/Common/ToastNotification.vue";

export default {
    components: {
        ToastNotification
    },
    props: ['slides', 'carouselId', 'documentName', 'isHtml'],
    setup(props) {
        const activeIndex = ref(0);
        const previewContainer = ref(null);
        const previewSlides = ref([]);

        const updatePreviewScroll = () => {
            if (previewContainer.value && previewSlides.value[activeIndex.value]) {
                previewSlides.value[activeIndex.value].scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center',
                    block: 'nearest'
                });
            }
        };

        const setupCarouselEventListener = () => {
            const carousel = document.getElementById(props.carouselId);
            if (carousel) {
                carousel.addEventListener('slide.bs.carousel', (event) => {
                    activeIndex.value = event.to;
                    updatePreviewScroll();
                });
            }
        };

        onMounted(() => {
            setupCarouselEventListener();
        });

        return {
            activeIndex,
            previewContainer,
            previewSlides,
            updatePreviewScroll
        };
    },
    data() {
        return {
            showIndicators: false,
            toastMessage: '', 
            toastTitle: '', 
            isFullscreen: false,
            showNavigation: !this.isHtml,
        };
    },
    methods: {
        goToSlide(slideIndex, showToast = true) {
            const indicator = document.querySelector(`#${this.carouselId} .carousel-indicators [data-bs-slide-to="${slideIndex}"]`);

            if (indicator) {
                indicator.click();
                const slideNumber = slideIndex + 1;
                if (showToast) {
                    this.showCustomToast(`Slide ${slideNumber} on document ${this.documentName}.`);
                }
                this.toggleIndicatorsVisibility();

            } 
            this.activeIndex = slideIndex;
            this.updatePreviewScroll();
        },
        toggleIndicatorsVisibility() {
            this.showIndicators = true;

            setTimeout(() => {
                this.showIndicators = false;
            }, 2000);
        },
        showCustomToast(message, title = 'Going to Slide') {

            const toastComponent = this.$refs['toastComponent' + this.carouselId];

            this.toastTitle = title;
            this.toastMessage = message;
            toastComponent.showToast();
        },
        toggleFullscreen() {
            this.isFullscreen = !this.isFullscreen;
        },
        handleOutsideClick(event) {
            if (this.isFullscreen && event.target === event.currentTarget) {
                this.isFullscreen = false;
            }
        }
    },
};
</script>

<style scoped>
.carousel-control-prev-icon,
.carousel-control-next-icon {
    /* Change the color of the arrow */
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    background-color: #000;
    border-radius: 50%; 
}

.carousel-indicators {
    /* Hide carousel indicators by default */
    opacity: 0;
    transition: opacity 1.5s ease;
    display: flex;
    justify-content: center;
    align-items: end;
    height: 5%;
    padding: 2.5% 0;
    pointer-events: auto;
    display: none;
}

/* Show indicators on hover */
.carousel-indicators:hover {
    opacity: 1;
}

.carousel-indicators button {
    pointer-events: auto;
    background-color: #000;
    border: 1px solid #fff;
    box-shadow: 0 0 2px #fff;
}

.carousel-indicators .active {
    background-color: #fff;
    border: 1px solid #000;
}

.is-visible {
     /* Override to ensure visibility */
    opacity: 1 !important;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* or a specific height if needed */
  overflow: hidden;
}

.carousel {
  flex: 1;
  min-height: 0; /* This allows the carousel to shrink if needed */
}

.preview-container {
  height: 132px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 9px 10px 2px;
}

.preview-slide {
  display: inline-block;
  height: 100px;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.preview-slide:hover,
.preview-slide.active {
  opacity: 1;
}

.preview-slide img,
.preview-slide .html-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.html-preview {
  font-size: 8px;
  overflow: hidden;
}

.fullscreen-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  display: none;
}

.fullscreen .fullscreen-toggle {
    right: 20px;
    top: 10px;
    background: none;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0 5vw;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen .carousel {
  flex: unset;
  min-height: unset;
}

.fullscreen .preview-container {
  background-color: #e8e8e8
}

@media screen and (min-width: 768px) {
  .fullscreen-toggle {
    display: block;
  }
}

@media screen and (max-width: 767px) {
.preview-container{
    height: 100px
  }
  .preview-slide {
    height: 70px;
  }
}

.fullscreen .fullscreen-toggle {
  right: 20px;
  top: 10px;
  background: none;
}
</style>