import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderPrecisionPicks = _resolveComponent("HeaderPrecisionPicks")!
  const _component_CompaniesToolbar = _resolveComponent("CompaniesToolbar")!
  const _component_CompaniesGridView = _resolveComponent("CompaniesGridView")!
  const _component_CompaniesListView = _resolveComponent("CompaniesListView")!
  const _component_ResultsPagination = _resolveComponent("ResultsPagination")!
  const _component_CompaniesSettings = _resolveComponent("CompaniesSettings")!
  const _component_PassModal = _resolveComponent("PassModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    false
      ? (_openBlock(), _createBlock(_component_HeaderPrecisionPicks, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_CompaniesToolbar, {
      onViewChange: _ctx.handleViewChange,
      onFiltersApplied: _ctx.handleFiltersApplied,
      onStatusUpdate: _ctx.updateCompaniesStatus,
      "is-actions-enabled": _ctx.selectedCompanyStatusEnabled,
      "is-precision-pick": false
    }, null, 8, ["onViewChange", "onFiltersApplied", "onStatusUpdate", "is-actions-enabled"]),
    (_ctx.isGridView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_CompaniesGridView, {
            companies: _ctx.companies,
            isPrecisionPicks: false,
            onUpdateStatus: _ctx.updateCompanyStatus,
            "onUpdate:isGridView": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isGridView = $event))
          }, null, 8, ["companies", "onUpdateStatus"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CompaniesListView, {
            companies: _ctx.companies,
            isPrecisionPicks: false,
            "handle-company-checkbox": _ctx.handleCompanyCheckbox,
            listViewSettings: _ctx.listViewSettings,
            listViewSettingsOrder: _ctx.listViewSettingsOrder,
            "is-actions-enabled": _ctx.selectedCompanyStatusEnabled,
            loading: _ctx.loading,
            "filters-applied": _ctx.getParams,
            onUpdateStatus: _ctx.updateCompanyStatus,
            "onUpdate:isGridView": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isGridView = $event))
          }, null, 8, ["companies", "handle-company-checkbox", "listViewSettings", "listViewSettingsOrder", "is-actions-enabled", "loading", "filters-applied", "onUpdateStatus"])
        ])),
    _createVNode(_component_ResultsPagination, {
      resultsCount: _ctx.resultsCount,
      companiesDisplayed: _ctx.companiesDisplayed,
      previousPage: _ctx.previousPage,
      nextPage: _ctx.nextPage,
      currentPage: _ctx.currentPage,
      pageSize: _ctx.pageSize,
      onPageChanged: _ctx.handlePageChange
    }, null, 8, ["resultsCount", "companiesDisplayed", "previousPage", "nextPage", "currentPage", "pageSize", "onPageChanged"]),
    _createVNode(_component_CompaniesSettings, {
      isPrecisionPicks: false,
      onListviewSettingChange: _ctx.handleListViewSettingChange,
      onListviewSettingOrder: _ctx.handleListViewOrderChange
    }, null, 8, ["onListviewSettingChange", "onListviewSettingOrder"]),
    _createVNode(_component_PassModal, {
      modelValue: _ctx.modalAction,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalAction) = $event)),
      "company-id": _ctx.selectedCompanyId,
      "company-name": _ctx.selectedCompanyName,
      "email-address": _ctx.selectedCompanyEmail,
      "recipient-name": _ctx.selectedCompanyRecipientName,
      "is-actions-enabled": _ctx.selectedCompanyStatusEnabled,
      onUpdateStatus: _ctx.updateCompanyStatus
    }, null, 8, ["modelValue", "company-id", "company-name", "email-address", "recipient-name", "is-actions-enabled", "onUpdateStatus"])
  ], 64))
}