<template>
    <span v-if="company.description" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-html="true"  data-bs-custom-class="wide-popover"
            :data-bs-content="description" class="popover-content">
        {{ company.description.split(' ').slice(0, 7).join(' ') }}...
        <router-link :to="'/company-report/' + company.id" class="text-primary fw-bold text-decoration-none clickable">view full report</router-link>
      </span>
  </template>
  
  <script>
  import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'
  import { computed, onMounted, onUnmounted  } from 'vue';
  import { marked } from 'marked';

  export default {
    props: ['company'],
    emits: companiesEmits,
    setup(props, { emit }) {
      const description = computed(() => {
        let description = marked(props.company.description);
        let content ="<div class='fs-13'>" + description + "</div>";
        return content;
      });

      return {
        description,
      };
    },
  };
  </script>

  <style>
  .wide-popover {
    max-width: 350px !important; 
  }

  @media (min-width: 768px) {
    .wide-popover {
      max-width: 450px !important;
    }
  }
  </style>