import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-valuenow"]
const _hoisted_2 = {
  key: 1,
  class: "badge w-100 bg-secondary"
}
const _hoisted_3 = {
  key: 2,
  class: "badge w-100 bg-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.gapClosingScore !== null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "progress",
        role: "progressbar",
        "aria-valuenow": _ctx.progressValue,
        "aria-valuemin": "0",
        "aria-valuemax": "100",
        style: {"height":"16px"}
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.progressBackgroundClass),
          style: _normalizeStyle({ width: `${_ctx.progressValue}%` })
        }, null, 6)
      ], 8, _hoisted_1))
    : (_ctx.gapClosingScore === null && _ctx.hasActiveFailures === true)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Partial Report"))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, "In Queue"))
}