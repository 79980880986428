import { CV_FOLLOWUP_URL, fetchStreamData, StreamResponse } from '@/utils/apiUtils';

export async function generateFollowup(company_id: number, reason: string): Promise<StreamResponse> {
  
  if (!company_id || typeof company_id !== 'number') throw new Error('Company ID is required and must be an integer');

  if (!reason || typeof reason !== 'string') throw new Error('Reason is required and must be a string');

  const endpoint = `${CV_FOLLOWUP_URL}/?company_id=${company_id}&reason=${reason}`;
  
  return fetchStreamData(endpoint);

}