<template>

    <HeaderPrecisionPicks v-if="false" />

    <CompaniesToolbar 
        @view-change="handleViewChange" 
        @filters-applied="handleFiltersApplied"
        @status-update="updateCompaniesStatus"
        :is-actions-enabled="selectedCompanyStatusEnabled"
        :is-precision-pick="false" 
    />

    <div v-if="isGridView">
        <CompaniesGridView :companies="companies" :isPrecisionPicks="false"
            @update-status="updateCompanyStatus"
            @update:isGridView="isGridView = $event" />
    </div>

    <div v-else>
        <CompaniesListView 
            :companies="companies" 
            :isPrecisionPicks="false"
            :handle-company-checkbox="handleCompanyCheckbox"
            :listViewSettings="listViewSettings"
            :listViewSettingsOrder="listViewSettingsOrder"
            :is-actions-enabled="selectedCompanyStatusEnabled"
            :loading="loading"
            :filters-applied="getParams"
            @update-status="updateCompanyStatus"
            @update:isGridView="isGridView = $event" />
    </div>

    <ResultsPagination 
        :resultsCount="resultsCount"
        :companiesDisplayed="companiesDisplayed"
        :previousPage="previousPage"
        :nextPage="nextPage" 
        :currentPage="currentPage"
        :pageSize="pageSize"
        @page-changed="handlePageChange"
    />

    <CompaniesSettings :isPrecisionPicks="false"  @listview-setting-change="handleListViewSettingChange" @listview-setting-order="handleListViewOrderChange" />

    <PassModal 
        v-model="modalAction"
        :company-id="selectedCompanyId"
        :company-name="selectedCompanyName"
        :email-address="selectedCompanyEmail"
        :recipient-name="selectedCompanyRecipientName"
        :is-actions-enabled="selectedCompanyStatusEnabled"
        @update-status="updateCompanyStatus"
    />
    

</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";
import CompaniesGridView from './Lists/CompaniesGridView.vue';
import CompaniesListView from './Lists/CompaniesListView.vue';
import HeaderPrecisionPicks from './Headers/HeaderPrecisionPicks.vue';
import CompaniesToolbar from './Toolbar/CompaniesToolbar.vue';
import CompaniesSettings from './Settings/CompaniesSettings.vue';
import { inboundService } from '@/api/inboundService';
import { statusService } from '@/api/statusService';
import ResultsPagination from '../Common/ResultsPagination.vue';
import { setupControlScale } from "@vue-leaflet/vue-leaflet/dist/src/functions/controlScale";
import { Modal } from "bootstrap"; 
import PassModal from '@/components/Common/PassModal.vue';

type Company = {
    id: number;
    logo: string;
    name: string;
    alignment: string;
    segments: string[];
    market_type: string[];
    status: string;
    status_reason: boolean | string;
    raised: string;
    description: string;
    quantity?: number | string; 
    will_use_status_feature?: boolean;
    inbound_reply_to_email?: string;
    inbound_reply_to_name?: string;
    customAnswers?: any[];
};

export default defineComponent({
    name: "CompaniesView",
    components: {
        CompaniesGridView,
        CompaniesToolbar,
        HeaderPrecisionPicks,
        CompaniesListView,
        CompaniesSettings,
        ResultsPagination,
        PassModal
    },
    setup() {
        const route = useRoute();
        const companies = ref<Company[]>([]);
        const resultsCount = ref(0);
        const companiesDisplayed = ref(0);
        const nextPage = ref(null);
        const previousPage = ref(null);
        const currentPage = ref(route.query.page ? parseInt(route.query.page as string) : 0);
        const pageSize = ref(25);
        const getParams = ref('');
        const statusReason = ref('');
        const selectedCompanyId = ref<number | null>(0);
        const selectedCompanyEmail = ref<string | null>(null);
        const selectedCompanyName = ref<string | null>(null);
        const selectedCompanyRecipientName = ref<string | null>(null);
        const selectedCompanyStatusEnabled = ref(false);
        const modalAction = ref(false);
        const listViewSettings = ref({});
        const listViewSettingsOrder = ref({});
        const companiesMap = ref(new Map<number, Company>());
        const loading = ref(false);

        const handleListViewSettingChange = (newSettings) => {
            listViewSettings.value = newSettings;
        };

        const handleListViewOrderChange = (newSettings) => {
            listViewSettingsOrder.value = newSettings;
        };

        const handlePageChange = async (page) => {
            currentPage.value = page;
            await getCompanyList(page);
        };

        const getCompanyList = async (page = currentPage.value) => {
            let params = getParams.value ? getParams.value : false;

            // Clear the companies list before fetching new data
            companies.value = [];

            loading.value = true;

            let response = await inboundService.getInboundCompanies(page, params);

            loading.value = false;

            currentPage.value = response.data.current_page;
            pageSize.value = response.data.page_size;

            resultsCount.value = response.data.total;
            nextPage.value = response.data.links.next;
            previousPage.value = response.data.links.previous;

            // Ensure response.data.results is an array
            if (Array.isArray(response.data.results)) {
                companies.value = response.data.results;
                companiesDisplayed.value = response.data.results.length;
                companies.value.forEach(company => {
                    companiesMap.value.set(company.id, company);
                });
                if ( companies.value.length > 0 ) {
                    selectedCompanyStatusEnabled.value = companies.value[0].will_use_status_feature === true;
                }
            } else {
                console.error('Expected an array but got:', response.data.results);
            }

            return response;
        };

        const handleFiltersApplied = async (filters, globalFilters, callback) => {
            let getParamsStr = "";

            // MAKE DRY
            if (filters && typeof filters === 'object') {

                for (let filter in filters) {
                    let filtersStr = filters[filter];
                    
                    if (filtersStr) {
                        if (filter != 'STARTDATE' && filter != 'ENDDATE') {
                            filtersStr = filters[filter].join(',');
                            filtersStr = filtersStr.replace('&', '%26')
                            filtersStr = filtersStr.replace('?', '%3f')   
                        }
                        
                        if (filters[filter].length > 0) {
                            if (getParamsStr !== "")
                                getParamsStr += "&";

                            getParamsStr += `${filter}=${filtersStr}`;
                        }
                    }
                }

            }

            if (globalFilters && typeof globalFilters === 'object') {

                for (let filter in globalFilters) {
                    let filtersStr = globalFilters[filter];

                    if (filtersStr) {
                        if (filter != 'STARTDATE' && filter != 'ENDDATE') {
                            filtersStr = globalFilters[filter].join(',');
                            filtersStr = filtersStr.replace('&', '%26')
                            filtersStr = filtersStr.replace('?', '%3f')   
                        }
                        
                        if (globalFilters[filter].length > 0) {
                            if (getParamsStr !== "")
                                getParamsStr += "&";

                            getParamsStr += `${filter}=${filtersStr}`;
                        }
                    }
                }

            }

            getParams.value = getParamsStr;
            currentPage.value = 0;

            await getCompanyList();

            callback();
        };

        onMounted(async () => {
            
            let response;

            try {
                response = await getCompanyList();
            } catch (err) {
                console.log(err);
            }
            
        });

        return {
            companies,
            companiesDisplayed,
            resultsCount,
            nextPage,
            previousPage,
            handleFiltersApplied,
            getCompanyList,
            handlePageChange,
            currentPage,
            pageSize,
            statusReason,
            companiesMap,
            selectedCompanyId,
            selectedCompanyName,
            selectedCompanyEmail,
            selectedCompanyRecipientName,
            selectedCompanyStatusEnabled,
            modalAction,
            listViewSettings,
            handleListViewSettingChange,
            listViewSettingsOrder,
            handleListViewOrderChange,
            loading,
            getParams
        };
    },
    data() {
        return {
            isGridView: false,
            isCollapsed: true,
            checkedCompanyIds: [] as number[],
        }
    },
    methods: {
        handleViewChange(isGridView) {
            this.isGridView = isGridView;
        },
        async updateCompanyStatus(id, status, reason = false, send = false) {

            if (!id || !status) return

            this.selectedCompanyId = id

            let company = this.companiesMap.get(id);
            if (company) {
                this.selectedCompanyName = company?.name ? company.name : null;
                this.selectedCompanyEmail = company?.inbound_reply_to_email ? company.inbound_reply_to_email : null;
                this.selectedCompanyRecipientName = company?.inbound_reply_to_name ? company.inbound_reply_to_name : null;
            }

            if (status === 'Passed' && (reason === false || reason === null)) {
                this.modalAction = true
                return;
            }

            for (let company of this.companies) {
                if (![id].includes(company.id)) continue;
                if (company.id !== id) continue;
                company.status_reason = reason;
                company.status = status;
                break;
            }

            this.statusReason = '';

            return;

        },
        async updateCompaniesStatus(status: string, ids: number[] | null = null) {

            let list_ids = this.checkedCompanyIds

            if (ids !== null) {
                if (!Array.isArray(ids) || ids.length === 0) {
                    throw new Error("Invalid or empty IDs array");
                }
                list_ids = ids
            }

            let response = await statusService.updateCompaniesStatus(list_ids, status);

            if (response.status === 200) {
                const statusToStrMap = {
                    'PASD': 'Passed',
                    'INPRG': 'In Progress',
                    '1STMT': '1st Meeting',
                    '2NDMT': '2nd Meeting',
                    'INVST': 'Invested',
                }


                for (let company of this.companies) {
                    if (list_ids.includes(company.id)) {
                        company.status = statusToStrMap[status];
                    }
                }
            }            
        },
        handleCompanyCheckbox(id, checked) {
            if (checked) {
                this.checkedCompanyIds.push(id);
            } else {
                const index = this.checkedCompanyIds.indexOf(id);
                if (index !== -1) {
                    this.checkedCompanyIds.splice(index, 1);
                }
            }
        }
    },
});
</script>