<template>
    <div class="d-flex align-items-center">
      <select
        v-model="selectedStatus"
        @change="handleStatusChange"
        :class="['form-select form-select-sm shadow-none fs-13 text-black', selectClass]"
        style="width: 130px"
        v-if="useStatusFeature"
      >
        <option v-for="option in statusOptions" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
      <span v-else>
        <button class="btn btn-outline-primary btn-sm" @click="generatePassEmail()"  style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
          {{ showInfoIcon ? 'Regenerate Pass Email' : 'Click to Draft Pass Email' }}
        </button>
      </span>

      <template v-if="showInfoIcon">
        <span
          v-if="statusReason"
          class="popover-content clickable ms-2"
          tabindex="0"
          data-bs-toggle="popover"
          data-bs-trigger="hover focus"
          :data-bs-content="`Previously passed due to: ${statusReason}`"
          >
          <i class="flaticon-info align-middle"></i>
        </span>
      </template>
    </div>
  </template>
  
  <script lang="ts">
import { defineComponent, ref, watch, computed, PropType } from 'vue';
import { statusService } from '@/api/statusService';

export default defineComponent({
  name: 'UpdateActions',
  props: {
    selectClass: {
      type: String,
      default: '',
    },
    initialStatus: {
      type: String,
      default: 'New',
    },
    statusReason: {
      type: [String, Boolean],
      default: '',
    },
    useStatusFeature: {
      type: Boolean,
      default: true,
    },
    id: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    }
  },
  emits: ['update-status', 'modal-action'],
  setup(props, { emit }) {
    const statusOptions = ['New', 'Passed', 'In Progress', '1st Meeting', '2nd Meeting', 'Invested'];

    const strToStatusMap: Record<string, string> = {
      'New': 'NEW',
      'Passed': 'PASD',
      'In Progress': 'INPRG',
      '1st Meeting': '1STMT',
      '2nd Meeting': '2NDMT',
      'Invested': 'INVST',
    };

    const selectedStatus = ref(props.initialStatus);

    const showInfoIcon = computed(() => props.initialStatus?.includes('Pass') ?? false);

    watch(() => props.initialStatus, (newStatus) => {
      selectedStatus.value = newStatus;
    });

    const generatePassEmail = () => {
      emit('update-status', props.id, 'Passed', false);
    };

    const handleStatusChange = async () => {
      const statusValue = strToStatusMap[selectedStatus.value];

      if (!statusValue) {
        console.error(`Invalid status: ${selectedStatus.value}`);
        return;
      }
      
      let reason: string | boolean = false;
      let fromPassModal = false;

      if (typeof props.statusReason === 'string') {
        reason = props.statusReason;
      }

      if (reason && statusValue !== 'PASD') {
        reason = false;
      }

      if (statusValue === 'PASD' && !reason) {
        emit('update-status', props.id, selectedStatus.value, reason);
        return;
      }

      try {
        const response = await statusService.updateCompaniesStatus([props.id], statusValue, reason);
        if (response.status === 200) {
          if (!fromPassModal) {
            emit('update-status', props.id, selectedStatus.value, reason);
          }
        }
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };

    return {
      selectedStatus,
      handleStatusChange,
      statusOptions,
      showInfoIcon,
      generatePassEmail
    };
  },
});
</script>