<template>
    <div :class="`card mb-25 rounded-0 bg-white letter-spacing ${global_tag == content_id ? 'border border-1 border-info' : 'border-0'}`" 
         v-if="content && content_id && title && !content.includes('No research was returned')" 
         :id="content_id">
        <div class="card-head bg-white d-md-flex align-items-center justify-content-between pt-25 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <h5 class="card-title fw-bold mb-0">
                {{ title }}
            </h5>
        </div>
        <div class="card-body pt-0 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
            <div @click="handleClick" v-html="processedContent"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'content', 'content_id', 'global_tag'],
    data() {
        return {
            isExpanded: false
        }
    },
    computed: {
        processedContent() {
            if (!this.content) return '';
            
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.content, 'text/html');
            
            // Hide ul elements
            const ulElements = doc.querySelectorAll('ul');
            ulElements.forEach(ul => {
                ul.style.cssText = this.isExpanded ? '' : 'display: none !important;';
            });
            
            // Hide references section
            const referencesP = Array.from(doc.querySelectorAll('p')).find(p => 
                p.textContent.trim() === 'References'
            );
            if (referencesP) {
                referencesP.style.cssText = this.isExpanded ? '' : 'display: none !important;';
            }
            
            // Hide ordered lists
            const olElements = doc.querySelectorAll('ol');
            olElements.forEach(ol => {
                ol.style.cssText = this.isExpanded ? '' : 'display: none !important;';
            });

            // Add toggle button after TLDR
            const tldrP = Array.from(doc.querySelectorAll('p')).find(p => 
                p.textContent.includes('TLDR:')
            );
            if (tldrP) {
                // Remove any existing toggle button first
                const existingButton = tldrP.querySelector('.toggle-button');
                if (existingButton) {
                    existingButton.remove();
                }
                
                const button = doc.createElement('button');
                button.className = 'toggle-button ms-2 text-primary border-0 bg-transparent text-decoration-underline';
                button.textContent = this.isExpanded ? 'Hide' : 'Show More';
                button.setAttribute('data-action', 'toggle');
                tldrP.appendChild(button);
            }
            
            return doc.body.innerHTML;
        }
    },
    methods: {
        handleClick(event) {
            if (event.target.matches('[data-action="toggle"]')) {
                this.isExpanded = !this.isExpanded;
            }
        },
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        }
    }
};
</script>

<style scoped>
:deep(.toggle-button) {
    cursor: pointer;
    padding: 0;
}
:deep(.toggle-button:hover) {
    opacity: 0.8;
}
</style>