<template>
    <div class="progress" role="progressbar" :aria-valuenow="progressValue" aria-valuemin="0" aria-valuemax="100" style="height: 16px;" v-if="gapClosingScore !== null">
        <div :class="progressBackgroundClass" :style="{ width: `${progressValue}%` }">
        </div>
    </div>
    <template v-else-if="gapClosingScore === null && hasActiveFailures === true">
        <span class="badge w-100 bg-secondary">Partial Report</span>
    </template>
    <template v-else>
        <span class="badge w-100 bg-secondary">In Queue</span>
    </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { getProgressBackgroundClass } from "@/utils/layoutUtils";

interface CustomAnswer {
  answer: string;
  answer_type: string;
  answer_type_label: string;
  proof: string | null;
}

export default defineComponent({
    name: "AlignmentScore",
    props: {
        inThesis: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
        gapClosingScore: {
            type: Number as PropType<number>,
            required: false,
            default: 0,
        },
        reputationFlags: {
            type: Array as PropType<unknown[]>,
            required: false,
            default: () => [],
        },
        softFailedFilters: {
            type: Array as PropType<unknown[]>,
            required: false,
            default: () => [],
        },
        hardFailedFilters: {
            type: Array as PropType<unknown[]>,
            required: false,
            default: () => [],
        },
        customAnswers: {
            type: Array as PropType<CustomAnswer[]>,
            required: false,
            default: () => [],
        },
        submission: {
            type: Array as PropType<unknown[]>,
            required: false,
            default: null,
        },
        hasActiveFailures: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
    },
    computed: {
        progressBackgroundClass(): string {
            let thesis = this.inThesis;
            if ( this.customAnswers.length > 0 ) {
                const customAnswerFails = this.customAnswers.filter(answer => 
                    answer.answer_type === "UACC"
                ).length > 0;
                if ( customAnswerFails ) {
                    thesis = false;
                }
            }
            return getProgressBackgroundClass(thesis, this.progressValue, this.gapClosingScore)
        },
        
        progressValue(): number {
            let score = this.gapClosingScore ? Math.round(this.gapClosingScore * 10) : 0
           
            if ( score < 60 ) {
                score = (score / 3) * 2
            }

            if (this.reputationFlags.length > 0) {
                score -= (this.reputationFlags.length * 5)
            }

            if (this.hardFailedFilters.length > 0) {
                switch (this.hardFailedFilters.length) {
                    case 4:
                        score = 5
                        break
                    case 3:
                        score = 10
                        break
                    case 2:
                        score = 15
                        break
                    case 1:
                        score = 20
                        break
                }
            }

            if ( this.customAnswers) {
                let customAnswerFails = 0;
                let failedScore = 0;
                let scoresToMinus = 0;
                let scoresToAdd = 0;
                this.customAnswers.forEach(answer => {
                    if ( answer.answer_type) {
                        switch(answer.answer_type) {
                            case "UACC":
                                customAnswerFails += 1
                                if ( this.hardFailedFilters.length > 0 ) {
                                    scoresToMinus -= 5
                                } else {
                                    if  (customAnswerFails === 1) {
                                        failedScore = 19
                                    } else {
                                        scoresToMinus -= 5
                                    }
                                }
                                break;
                            case "ACC":
                                if ( customAnswerFails === 0 ) {
                                    scoresToAdd += 5
                                }
                                break;
                        }
                    }  
                });
                score = score + scoresToAdd + scoresToMinus
                if ( failedScore > 0 ) {
                    score = failedScore + scoresToMinus
                }
            }

            return Math.max(3, Math.min(100, score));        

        }
    }
});
</script>