<template>
    <div class="d-flex">
      <div class="form-check mb-0 ms-10">
        <input
          v-if="company.gap_closing_score !== null || company?.custom_answers?.length > 0" 
          class="form-check-input shadow-none company-check-box" 
          type="checkbox" 
          v-bind:id="company.id"
          @click="$emit('company-checkbox', company.id, $event.target.checked)"
        />
      </div>
      <div class="d-flex align-items-center ms-1 fs-md-15 fs-lg-16">
        <router-link :to="'/company-report/?company_name='+company.name+'&company_website='+company.website" class="text-decoration-none">
          {{ company.name.length > 30 ? company.name.substring(0, 30) + '...' : company.name }}
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

  export default {
    props: ['company'],
    emits: companiesEmits,
  };
  </script>