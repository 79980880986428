<template>
    <b-modal v-model="isOpen"
    centered :title="!streamedData && !isDrafting ? 'Thinking...' : (isDrafting && !streamedData ? 'Writing draft...' : 'Your 1st Look Draft')" hide-footer :dialog-class="streamedData ? 'modal-md' : 'modal-sm'" @hide="close">
      <div class="d-block">

        <div v-if="isDrafting && !streamedData">
          <div class="d-flex flex-column align-items-center justify-content-center mb-3">
            <div class="spinner-border mt-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>

        <div class="position-relative" v-if="streamedData && isDrafting">
          <textarea v-model="streamedData" readonly style="display:none"></textarea>
          <div class="mt-3 text-bg-light p-3 fs-md-13 fs-lg-14">
            <template v-if="!isStreaming">
              <div v-html="streamedData"></div>
            </template>
            <template v-else>
              <div v-html="streamedData.replace(/\n/g, '<br>')"></div>
            </template>
          </div>
          <button @click="copyToClipboard" class="btn btn-sm btn-secondary position-absolute bottom-0 end-0 mb-1 me-1">
            <i class="flaticon-copy"></i> 
          </button>
        </div>
      </div>
  
    </b-modal>

  </template>
  
  <script lang="ts">
  import { defineComponent, ref, watch} from 'vue';
  import { StreamResponse } from '@/utils/apiUtils';
  import * as investmentmemoService from '@/api/investmentmemoService';
  import { marked } from 'marked'; 

  export default defineComponent({
    name: 'MemoModal',
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
      companyId: {
        type: Number,
        required: true,
      },
    },
    emits: ['update:modelValue', 'update-status'],
    setup(props, { emit }) {
      const isOpen = ref(props.modelValue);
      const streamedData = ref('');
      const isDrafting = ref(false);
      const isStreaming = ref(false);
    
      watch(() => props.modelValue, (newValue) => {
        isOpen.value = newValue;
        if (isOpen.value === true) {
            createDraft();
        } else {
            close();
        }
      });
  
      watch(isOpen, (newValue) => {
        emit('update:modelValue', newValue);
      });
  
      const close = () => {
        isOpen.value = false;
        isDrafting.value = false;
        streamedData.value = '';
        isStreaming.value = false;
      };
  
      const  createDraft = async () => {

        isDrafting.value = true;
        isStreaming.value = false;
        try {
          const stream: StreamResponse = await investmentmemoService.generateDraft(props.companyId);

          isStreaming.value = true;

          for await (const chunk of stream) {
              if ( !isDrafting.value) break;
              streamedData.value += chunk;
          }

          isStreaming.value = false;

          const markedContent = marked(streamedData.value);
          if (markedContent instanceof Promise) {
            streamedData.value = await markedContent;
          } else {
            streamedData.value = markedContent;
          }


        } catch (error) {
            console.error('Error generating followup:', error);
            isDrafting.value = false;
        }
       
      };

      const copyToClipboard = () => {
        // Create a temporary element
        const tempElement = document.createElement('div');
        
        // Set the innerHTML of the temp element to your formatted HTML
        tempElement.innerHTML = streamedData.value;
        
        // Set inline styles to ensure no background color is applied
        tempElement.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border: 0;
          background: white !important;
          color: black;
        `;
        
        // Append the temp element to the body
        document.body.appendChild(tempElement);
        
        // Create a range and selection
        const range = document.createRange();
        range.selectNode(tempElement);
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        
        try {
          // Execute the copy command
          const successful = document.execCommand('copy');
          if (successful) {
            alert('Copied to clipboard!');
          } else {
            console.error('Unable to copy');
          }
        } catch (err) {
          console.error('Error in copying text: ', err);
        } finally {
          // Clean up: remove the temporary element
          document.body.removeChild(tempElement);
          // Clear the selection
          selection?.removeAllRanges();
        }
      };
  
      return {
        isOpen,
        close,
        streamedData,
        isStreaming,
        copyToClipboard,
        isDrafting,
        createDraft
      };
    },
  });
  </script>