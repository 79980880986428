<template>
    <div>

        <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing" v-if="(customAnswers && customAnswers.length > 0) || (inboundEmailBody && inboundEmailBody.length > 0)">
            <div class="leads-details-tabs">
                <div class="tab-content" id="tabContent">
                    <div class="tab-pane fade" :class="{ 'show active': index === 0 }" v-for="(document, index) in referenceDocuments" :key="index" :id="'carouselTab'+document['document_id']+'-pane'">
                        <SlideCarousel :ref="'slideCarousel' + document['document_id']" :carouselId="'carouselId'+document['document_id']" :slides="document['document_urls']" :documentName="document['document_name']" :isHtml="document['is_html']"/>
                    </div>
                </div>
                <div class="card-body pt-30 pt-0 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" v-if="referenceDocuments.length > 1">
                    <h6 class="fw-bold">Pitch Decks & Documents:</h6>
                    <ul class="nav align-items-center" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="(document, index) in referenceDocuments" :key="index">
                            <button
                                class="btn btn-sm btn-outline-primary me-2 transition d-flex align-items-center"
                                :class="{ 'active': index === 0 }"
                                :id="'carouselTab'+document['document_id']"
                                data-bs-toggle="tab"
                                :data-bs-target="'#carouselTab'+document['document_id']+'-pane'"
                                type="button"
                                role="tab"
                                :aria-controls="'carouselTab'+document['document_id']+'-pane'"
                                :aria-selected="index === 0"
                            >
                                <i class="flaticon-document me-1 mt-5"></i>
                                {{ document['document_name'].split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') }}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-body pt-20 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30">
                <div v-for="(answer, index) in customAnswers" :key="index">
                    <span>
                        <span class="fw-bold">
                            {{ answer.custom_question_data.question_short }}
                            <span v-if="answer.answer_type_label !== 'Not Applicable'">
                                <span v-if="answer.answer_type_label === 'Acceptable'" class="text-center ms-2">
                                    <i class="flaticon-check text-success"></i>
                                </span>
                                <span v-else-if="answer.answer_type_label === 'Unacceptable'" class="text-center ms-2">
                                    <i class="flaticon-close-1 text-danger"></i>
                                </span>
                                <span v-else class="text-center ms-2">
                                    <span class="text-warning fs-6 fw-bold">?</span>
                                </span>
                            </span>
                        </span>
                    </span>

                    <div class="ms-3 mt-20 mb-30">
                        
                        <p>
                            <strong>{{ answer.answer.charAt(0).toUpperCase() + answer.answer.slice(1) }}</strong> 
                        </p>
                        <div>
                            <div>
                                <p>
                                    {{ answer.proof.charAt(0).toUpperCase() + answer.proof.slice(1) }}
                                </p>
                            </div>
                        </div>

                        <p>
                            <span class="fw-bold">Sources:
                                <span v-for="(reference, refIndex) in answer.references" :key="refIndex">
                                    <template v-if="reference.document_id !== 'Form Submission'">
                                        <a class="reference-link ms-1 fs-17 clickable" 
                                            @click="goToSlide(reference.document_id, reference.document_page - 1)"
                                            @mouseenter="showPreview(reference.document_id, reference.document_page - 1)"
                                            @mouseleave="hidePreview"
                                        >
                                            {{refIndex + 1}}
                                    </a>
                                    </template>
                                    <template v-else>
                                        <a class="reference-link ms-1 fs-17 clickable" @click="scrollTo('inbound-email-body')">
                                            {{refIndex + 1}}
                                        </a>
                                    </template>
                                </span>
                            </span> 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing" v-if="(inboundEmailBody && inboundEmailBody.length > 0)">
            <div class="card-body pt-20 pb-15 pb-sm-20 pb-md-25 pb-lg-30 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" id="inbound-email-body">
                <h6 class="fw-bold mb-30">Inbound Submission(s):</h6>
                <div v-html="inboundEmailBody"></div>
            </div>
        </div>
        <ToastNotification ref="toastComponent" :id="'custom-answers'" :title="toastTitle" :message="toastMessage"/>
        <MemoModal 
            v-model="modalAction"
            :company-id="Number(companyID)"
        />
    </div>
    <div v-if="previewVisible" class="slide-preview" :style="previewStyle">
        <img :src="previewImageUrl" alt="Slide Preview" />
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import SlideCarousel from '@/components/Common/SlideCarousel.vue';
import ToastNotification from "@/components/Common/ToastNotification.vue";
import MemoModal from '@/components/Common/MemoModal.vue';

export default {
    props: ['customAnswers', 'referenceDocuments', 'inboundEmailBody', ],
    components: { SlideCarousel, ToastNotification },
    data() {
        return {
            toastTitle: '',
            toastMessage: '',
            detailsVisible: {},
            previewVisible: false,
            previewImageUrl: '',
            previewStyle: {
                position: 'fixed',
                zIndex: 1000,
                bottom: '0px',
                left: '0px',
            },
        };
    },
    methods: {
        scrollTo( html_id ) {
            const element = document.getElementById(html_id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                this.showCustomToast('Scrolling down to...', 'Inbound Submission(s)');
            }
        },
        showCustomToast(title, message) {
            this.toastTitle = title;
            this.toastMessage = message;
            this.$nextTick(() => {
                this.$refs.toastComponent.showToast();
            });
        },
        goToSlide(documentId, slideIndex) {
            const isFormSubmission = documentId === 'Form Submission';

            if (isFormSubmission) {
                documentId = 'inbound_email_body'
            }

            const carousel = this.$refs['slideCarousel' + documentId] ? this.$refs['slideCarousel' + documentId][0] : null;
            const tabPane = document.getElementById('carouselTab'+documentId+'-pane');

            if (carousel && tabPane) {
                // Check if the tab is active
                if (!tabPane.classList.contains('active')) {
                    // If not, activate the tab
                    const tab = document.getElementById('carouselTab'+documentId);
                    tab.click();
                }

                if (isFormSubmission) {
                    carousel.showCustomToast('Inbound Email', 'Going To')
                } else {
                    carousel.goToSlide(slideIndex);
                }
            }
        },
        toggleDetails(index) {
            this.detailsVisible[index] = !this.detailsVisible[index]
        },
        showPreview(documentId, slideIndex) {
            const document = this.referenceDocuments.find(doc => doc.document_id === documentId);
            if (document && document.document_urls[slideIndex]) {
                this.previewImageUrl = document.document_urls[slideIndex];
                this.previewVisible = true;
                this.$nextTick(() => {
                    this.updatePreviewPosition(event);
                });
            }
        },    
        hidePreview() {
            this.previewVisible = false;
        },
        updatePreviewPosition(event) {
            const offset = 10; // Distance from cursor
            const isMobile = window.innerWidth <= 768; // Adjust this breakpoint as needed
            
            const previewWidth = isMobile ? 400 : 600; // Smaller width for mobile
            const previewHeight = isMobile ? 300 : 350; // Smaller height for mobile
            
            // Position above the cursor
            this.previewStyle.bottom = `${window.innerHeight - event.clientY + offset}px`;
            this.previewStyle.left = `${event.clientX + offset}px`;
            
            // Ensure the preview doesn't go off-screen to the right
            if (event.clientX + offset + previewWidth > window.innerWidth) {
                this.previewStyle.left = `${window.innerWidth - previewWidth - offset}px`;
            }
            
            // Ensure the preview doesn't go off-screen at the top
            if (event.clientY - previewHeight - offset < 0) {
                this.previewStyle.bottom = `${window.innerHeight - event.clientY - previewHeight - offset}px`;
            }
            
            // Ensure the preview doesn't go off-screen to the left
            if (event.clientX - offset < 0) {
                this.previewStyle.left = `${offset}px`;
            }
            
            // Update max-width and max-height based on screen size
            this.previewStyle.maxWidth = `${previewWidth}px`;
            this.previewStyle.maxHeight = `${previewHeight}px`;
        },
    },
    mounted() {
        window.addEventListener('mousemove', this.updatePreviewPosition);
    },
    beforeUnmount() {
        window.removeEventListener('mousemove', this.updatePreviewPosition);
    },
};
</script>

<style scoped>
.btn {
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
}
.slide-preview {
  position: fixed;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 5px;
}

.slide-preview img {
  width: 100%;
  height: auto;
  object-fit: contain; 
}

/* Add responsive styles */
@media (max-width: 768px) {
    .slide-preview {
        padding: 3px;
    }
}
</style>