<template>
  <UpdateActions
    :initial-status="company.status"
    :status-reason="company.status_reason"
    :id="company.id"
    :use-status-feature="company.will_use_status_feature"
    @update-status="handleUpdateStatus"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { companiesEmits } from '@/components/Companies/CompaniesUtils.js';
import UpdateActions from '@/components/Common/UpdateActions.vue';

export default defineComponent({
  name: 'CompanyActionsCell',
  components: {
    UpdateActions,
  },
  props: ['company'],
  emits: companiesEmits,
  setup(props, { emit }) {
    const handleUpdateStatus = (id, status, reason ) => {
      emit('update-status',  id, status, reason );
    };

    return {
      handleUpdateStatus,
    };
  },
});
</script>