export const company_research_data_defaults = {
    INFO: null,
    PROD: null,
    TARAUD: null,
    PRODCOST: null,
    BIPOCBEN: null,
    NEWS: null,
    EREVIEWS: null,
    UREVIEWS: null,
    LEGAL: null,
}

export const company_research_display_order = [
    'NEWS',
    'EREVIEWS',
    'UREVIEWS',
    'PROD',
    'TARAUD',
    'PRODCOST',
    'BIPOCBEN',
    'LEGAL'
]

export const research_data_title_map = {
    INFO: 'Company Info',
    PROD: 'Product',
    TARAUD: 'Target Audience',
    PRODCOST: 'Cost Of Product',
    BIPOCBEN: 'Impact & Ethics',
    NEWS: 'News',
    EREVIEWS: 'Employee Reviews',
    UREVIEWS: 'User Reviews',
    LEGAL: 'Legal',
}

export function getCompanyDefaults() {
    return {
        id: '',
        name: '',
        website: '',
        raw_data: '',
        logo: '',
        research_status: 'Deploying AI Agents',
        gap_closing_category: {
            CUR: {
                score: 0,
                label: '',
                analysis: '',
                references: '',
                title: '',
            },
            POT: {
                score: 0,
                label: '',
                analysis: '',
                references: '.',
                title: '',
            },
            AVG: {
                score: 0,
                label: '',
                analysis: '',
                references: '',
                title: '',
            },
        },
        supplemental_data: {
            investment_questions: '',
        },
        references: '',
        references_count: 0,
        reputation_risks: {
            flags: [],
            analysis: '',
        },
        in_thesis: null,
        thesis_failed_filters: {
            hard_filters: [],
            soft_filters: [],
        },
        funding_rounds: [],
        status: null,
        status_reason: null,
        will_use_status_feature: null,
        inbound_reply_to_email: null,
        inbound_reply_to_name: null,
        customAnswers: [],
        partial_report: false,
        incomplete_inbound_report: false,
    };
}

export const label_defaults = {
    POT_gap_label: 'is-loading',
    CUR_gap_label: 'is-loading',
    AVG_gap_label: 'is-loading',
};