import { fetchStreamData, CV_INVESTMENTMEMO_URL, StreamResponse } from '@/utils/apiUtils';

export async function generateDraft(company_id: number): Promise<StreamResponse> {
  
    if (!company_id || typeof company_id !== 'number') throw new Error('Company ID is required and must be an integer');

    const endpoint = `${CV_INVESTMENTMEMO_URL}/?company_id=${company_id}`;
  
    return fetchStreamData(endpoint);

}