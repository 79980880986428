import { RouteLocationNormalized } from 'vue-router';

let router = null;

export const setRouter = (r) => {
  router = r;
};

export const getRouter = () => router;

export const preserveParams = (route) => {
  const preservedParams = ['hide_header', 'source']; // Add any other params you want to preserve
  return Object.fromEntries(
    Object.entries(route.query || {})
      .filter(([key]) => preservedParams.includes(key))
  );
};