export const setGapTitle = (label) => {

    switch (label) {
    
        case 'GW':
            return "Gap Widening";
        case 'GN':
            return "Gap Neutral";
        case 'GC':
            return "Gap Closing";
    
    }
    
    return false;
    
    }
    
export const setGapCSS = (label) => {
    
    
    switch (label) {
    
        case 'GW':
            return "is-warning";
        case 'GC':
            return "is-success";
    
    }
    
    return "";
    
    }

export const updateCompanyGapGraphScores = (raw_json ) => {

        let score_edu = 0
        let score_lower = 0
        let score_middle = 0
        let score_bipoc = 0
        let score_environment = 0
        let score_pricing = 0
        let score_ethics = 10


        let label_edu = false
        let label_lower = false
        let label_middle = false
        let label_bipoc = false
        let label_environment = false
        let label_pricing = false
        let label_ethics = ''
  
        const calculateCategoryScore = (mission_field, category_field, category_reason) => {

            let label = false;
            let score = 1;

            // If mission_field or category_field is true, set score to 10
            if (mission_field === true || category_field === true) {
                score = 10;
            }

            // If mission_field is false and category_field is true, set score to 7 and label to category_reason
            if (mission_field === false && category_field === true) {
                score = 7;
                label = "Although not stated in the mission the company has high potential ("
                label += category_reason.toLowerCase();
                label += ")"
            }

            if (category_field === 'high-alignment') {
                score = 7;
                label = "Has potential ("
                label += category_reason.toLowerCase();             
                label += ")"
            }

            if (category_field === 'some-alignment') {
                score = 5;
                label = "Some ("
                label += category_reason.toLowerCase();                
                label += ")"
            }

            if (category_field === 'low-alignment') {
                score = 4;
                label = "Slight ("
                label += category_reason.toLowerCase();
                label += ")"
            }

            // If category_reason is provided, check for 'indirect' in the reason
            if (category_reason) {
                let lower_case_reason = category_reason.toLowerCase();

                if (lower_case_reason.includes('indirect')) {
                    score = 4;
                    label = category_reason;
                }
            }

            return [score, label];
        };
  
        const edu  = calculateCategoryScore(
          raw_json.mission['mission_education-class_explicit'],
          raw_json['gap-closing-categories']['education'] ,
          raw_json['gap-closing-categories']['education_reasoning']
        )
        score_edu = edu[0]
        label_edu = edu[1]
  
        const lower = calculateCategoryScore(
          raw_json.mission['mission_poverty_explicit'],
          raw_json['gap-closing-categories']['lower-income'] ,
          raw_json['gap-closing-categories']['lower-income_reasoning']
        )
        score_lower = lower[0]
        label_lower = lower[1]
  
        const middle = calculateCategoryScore(
          raw_json.mission['mission_middle-class_explicit'],
          raw_json['gap-closing-categories']['middle-class'] ,
          raw_json['gap-closing-categories']['middle-class_reasoning']
        )
        score_middle = middle[0]
        label_middle = middle[1]
  
        const bipoc = calculateCategoryScore(
          raw_json.mission['mission_bipoc_explicit'],
          raw_json['gap-closing-categories']['bipoc'] ,
          raw_json['gap-closing-categories']['bipoc_reasoning']
        )
        score_bipoc = bipoc[0]
        label_bipoc = bipoc[1]
  
        const env = calculateCategoryScore(
          raw_json.mission['mission_environmental-infrastructure_explicit'],
          raw_json['gap-closing-categories']['environmental-infrastructure'] ,
          raw_json['gap-closing-categories']['environmental-infrastructure_reasoning']
        )
        score_environment = env[0]
        label_environment = env[1]
  
        if (!raw_json.product_pricing.is_high_end_consumer_good) {
            score_pricing += 5;
        }
  
        if (!raw_json.sales_strategies.sliding_scale) {
            score_pricing += 2.5;
        } else {
            label_pricing = "Sliding scale pricing is not gap-closing."
        }
  
        if (!raw_json.sales_strategies.buy_one_give_one) {
            score_pricing += 2.5;
        } else {
            label_pricing = "Buy one give one is not gap-closing."
        }
  
        if (raw_json.sales_strategies.is_discretionary) {
            score_pricing -= 5;
            label_pricing = "Is discretionary product."
        }
  
        if (raw_json.money_scams.has_complaints === true || raw_json.money_scams.has_complaints === true) {
            score_ethics = 1;
            label_ethics += "financial complaints"
        }
  
        if (raw_json.legal_analysis.has_lawsuits === true) {
            score_ethics = 1;
            if (label_ethics !== "") { label_ethics += ", " }
            label_ethics += "lawsuits"
        }
  
        if (raw_json.legal_analysis.has_discrimination_complaints === true) {
            score_ethics = 1;
            if (label_ethics !== "") { label_ethics += ", " }
            label_ethics += "discrimination complaints"
        }

        if (raw_json.user_reviews && raw_json.user_reviews.is_sentiment_negative === true) {
            score_ethics = 1;
            if (label_ethics !== "") { label_ethics += ", " }
            label_ethics += "user concerns"
        }

        if (raw_json.employee_reviews && raw_json.employee_reviews.is_sentiment_negative === true) {
            score_ethics = 1;
            if (label_ethics !== "") { label_ethics += ", " }
            label_ethics += "employee concerns"
        }
  
        if (label_ethics === "") {
            label_ethics = false;
        }

        let results = {
            scores: [score_edu, score_lower, score_middle, score_bipoc, score_environment, score_ethics, score_pricing],
            labels:  [
                [label_edu, label_lower, label_middle, label_bipoc, label_environment, label_ethics, label_pricing]
            ]
        }
  
        return results
      
};

export const flagMap = {
    'legal_flag': { label: 'Legal Concerns', report: 'report-LEGAL' },
    'user_reviews_flag': { label: 'Customer Concerns', report: 'report-UREVIEWS' },
    'employee_reviews_flag': { label: 'Employee Concerns', report: 'report-EREVIEWS' }
};