<template>
    <div class="container py-5">
      <h1 class="mb-4">Terms of Service for Kapor Center Enterprises (kapor.ai and/or beta.kapor.ai)</h1>
      <p class="lead">Last Updated: {{ currentDate }}</p>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">1. Introduction</h4>
          <p>These Terms of Service ("Terms") govern your use of the kapor.ai platform ("Service") provided by Kapor Center Enterprises ("we", "us", or "our"). By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Service.</p>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">2. Description of Service</h4>
          <p>Kapor.ai is a platform that analyzes and processes information from submitted decks, forms, and emails of pitches using various Large Language Models (LLMs) to provide output in reports and structured data.</p>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">3. User Accounts</h4>
          <ol>
            <li>To access the Service, you must create an account by providing accurate and complete information.</li>
            <li>You are responsible for maintaining the confidentiality of your account and password.</li>
            <li>You agree to notify us immediately of any unauthorized use of your account.</li>
          </ol>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">4. Use of the Service</h4>
          <p>4.1. You agree to use the Service only for lawful purposes and in accordance with these Terms.</p>
          <p>4.2. You shall not:</p>
          <ul>
            <li>a) Use the Service in any way that violates any applicable laws or regulations.</li>
            <li>b) Attempt to gain unauthorized access to any part of the Service.</li>
            <li>c) Interfere with or disrupt the integrity or performance of the Service.</li>
            <li>d) Reverse engineer, decompile, or attempt to extract the source code of the Service.</li>
          </ul>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">5. Content Submission</h4>
          <ol>
            <li>By submitting content (including decks, forms, and emails) to the Service, you grant us the right to process, analyze, and store this content for the purpose of providing the Service.</li>
            <li>You represent and warrant that you have the necessary rights to submit the content and that it does not infringe on any third-party rights.</li>
            <li>You acknowledge that the content you submit will be processed by LLMs and other automated systems.</li>
          </ol>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">6. Data Processing and Privacy</h4>
          <ol>
            <li>We process personal data in accordance with our Privacy Policy, which is incorporated by reference into these Terms.</li>
            <li>You acknowledge that the Service uses LLMs and other AI technologies to process and analyze submitted content. These technologies may retain information for their own legal obligations.</li>
            <li>We implement appropriate technical and organizational measures to protect your data, but we cannot guarantee absolute security.</li>
            <li>You are responsible for ensuring that your use of the Service complies with all applicable data protection laws.</li>
          </ol>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">7. Intellectual Property Rights</h4>
          <ol>
            <li>The Service, including its software, design, and content created by us, is protected by copyright and other intellectual property rights owned by or licensed to us.</li>
            <li>You retain all rights to the content you submit to the Service. However, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, and process your content for the purpose of providing and improving the Service.</li>
          </ol>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">8. Disclaimer of Warranties</h4>
          <ol>
            <li>The Service is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.</li>
            <li>We do not warrant that the Service will be uninterrupted, error-free, or completely secure.</li>
            <li>We make no warranties regarding the accuracy, reliability, or completeness of any output or analysis provided by the Service.</li>
          </ol>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">9. Limitation of Liability</h4>
          <ol>
            <li>To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the Service.</li>
            <li>Our total liability for any claims arising under these Terms shall be limited to the amount you paid for the Service in the 12 months preceding the claim.</li>
          </ol>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">10. Termination</h4>
          <ol>
            <li>We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason.</li>
            <li>Upon termination, your right to use the Service will cease immediately.</li>
          </ol>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">11. Changes to the Service and Terms</h4>
          <ol>
            <li>We reserve the right to modify or discontinue the Service at any time without notice.</li>
            <li>We may revise these Terms from time to time. The most current version will always be posted on our website.</li>
          </ol>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">12. Governing Law and Jurisdiction</h4>
          <p>These Terms shall be governed by and construed in accordance with the laws of California, without regard to its conflict of law provisions. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Alameda County.</p>
        </div>
      </div>
  
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title">13. Contact Information</h4>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <p>Kapor Center Enterprises<br>2148 Broadway, Oakland, CA 94612<br><br/>Email: info@kapor.ai</p>
        </div>
      </div>
  
      <p class="mt-4">By using the kapor.ai Service, you acknowledge that you have read and understood these Terms and agree to be bound by them.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TermsPage',
    data() {
      return {
        currentDate: new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      }
    }
  }
  </script>
  
  <style scoped>
  .card {
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .card-title {
    color: #333;
    font-weight: bold;
  }
  h2 {
    color: #555;
    margin-top: 1.5rem;
  }
  </style>