<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/"
        class="d-flex align-items-center text-white text-decoration-none"
      >
        <img src="../../assets/images/kc-white-logo.png" alt="logo-icon" style="max-width: 80%;"/>
      </router-link>
      <div class="border-bottom"></div>
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
        class="sidebar-navbar-nav ps-0 mt-25 mb-0 list-unstyled accordion"
        id="sidebarNavAccordion"
      >
        <li class="sidebar-nav-item" v-if="canAccessFeature('dashboard')">
          <router-link to="/" class="sidebar-nav-link d-block">
            <i class="flaticon-more-1"></i>
            <span class="title">Dashboard</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item" v-if="canAccessFeature('aiPrecisionPicks')">
          <router-link to="/ai-precision-picks" class="sidebar-nav-link d-block">
            <i class="flaticon-taget"></i>
            <span class="title">AI Precision Picks</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/search" class="sidebar-nav-link d-block">
            <i class="flaticon-search"></i>
            <span class="title">Company Search</span>
          </router-link>
        </li>
        <li class="sub-title sidebar-nav-item" v-if="canAccessFeature('inboundList')">
          <span class="d-block text-uppercase fw-medium">Lists</span>
        </li>
        <li class="sidebar-nav-item" v-if="canAccessFeature('inboundList') ">
          <router-link to="/inbound" class="sidebar-nav-link d-block">
            <i class="flaticon-envelope"></i>
            <span class="title">Inbound</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item"  v-if="canAccessFeature('favorites')">
          <div class="sidebar-nav-link d-block disabled">
            <i class="flaticon-like"></i>
            <span class="title">Favorites</span>
          </div>
        </li>
        <li class="sidebar-nav-item" v-if="canAccessFeature('customLists')">
          <div class="sidebar-nav-link d-block disabled">
            <i class="flaticon-layer"></i>
            <span class="title">Custom Lists</span>
          </div>
        </li>
        <li class="sub-title sidebar-nav-item">
          <span class="d-block text-uppercase fw-medium">Account Info</span>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0" v-if="canAccessFeature('accountSettings')"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseTwentyTwo"
            aria-expanded="false"
            aria-controls="sidebarCollapseTwentyTwo"
          >
            <i class="flaticon-setting"></i>
            <span class="title">Settings</span>
          </a>
          <div
            id="sidebarCollapseTwentyTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                    to="/account-settings"
                    class="sidebar-sub-menu-link"
                  >
                    Account Setting
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                    to="/organization-settings"
                    class="sidebar-sub-menu-link"
                  >
                    Org Setting
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                    to="/settings-users"
                    class="sidebar-sub-menu-link"
                  >
                    User Settings
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                    to="/settings-change-password"
                    class="sidebar-sub-menu-link"
                  >
                    Change Password
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                    to="/settings-delete-account"
                    class="sidebar-sub-menu-link"
                  >
                    Delete Account
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/logout" class="sidebar-nav-link d-block">
            <i class="flaticon-logout"></i>
            <span class="title">Logout</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import stateStore from "@/stores/stateStore";
import { canAccessFeature } from "@/utils/featuresUtils"

export default defineComponent({
  name: "MainSidebar",
  setup() {
    const stateStoreInstance = stateStore;
    return {
      stateStoreInstance,
      canAccessFeature
    };
  },
});
</script>