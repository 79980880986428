<template>
    <i class="flaticon-eye position-relative top-2 fs-18 clickable" 
       data-bs-toggle="modal" 
       data-bs-target=".contentModalScrollable"
       @click="$emit('company-selected', company)"></i>
</template>

<script lang="ts">
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

import { defineComponent } from "vue";

export default defineComponent({
  name: "CompanySubmissionCell",
  props: ['company'],
  emits: companiesEmits,
});
</script>