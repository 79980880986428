<template>
        <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div
            class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
            <div class="d-sm-flex align-items-center">
                <div class="select-calendar position-relative me-sm-10 ms-sm-15 mt-10 mt-sm-0" v-if="!isPrecisionPicks">
                    <i class="flaticon-calendar lh-1 icon"></i>
                    <input ref="timeline" type="text"
                        class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                        id="timeline"/>
                </div>
                <div class="dropdown mt-10 mt-sm-0 ms-sm-10 me-4">
                    <button
                        class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                        type="button" @click="toggleCollapse" aria-expanded="false">
                        <i class="ph ph-funnel"></i>
                    </button>
                </div>
            </div>
            <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
                <template v-if="!isGridView && canAccessFeature('inboundListActions') && isActionsEnabled">

                    <select v-model="selectedStatus" class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0">
                        <option value="PASD">Pass</option>
                        <option value="INPRG">In Progress</option>
                        <option value="1STMT">1st Meeting</option>
                        <option value="2NDMT">2nd Meeting</option>
                        <option value="INVST">Invested</option>
                    </select>
                    <button
                        class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none ms-sm-10 me-2"
                        @click="callStatusUpdateFunction"
                    >
                        Do Action
                    </button>

                </template>

                <div class="dropdown me-3 mt-10 mt-sm-0 ms-sm-10">
                    <button
                        class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                        type="button" @click="toggleView">
                        <i :class="isGridView ? 'flaticon-menu-1' : 'flaticon-menu-3'"></i>

                    </button>
                </div>

                <div class="mt-10 mt-sm-0 ms-sm-10" v-if="!isGridView">
                    <button
                        class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                        type="button" data-bs-toggle="offcanvas" data-bs-target="#companiesSettings" aria-controls="offcanvasRight">
                        <i class="flaticon-setting"></i>

                    </button>
                </div>

            </div>
        </div>
        <div v-if="!isCollapsed" class="d-flex flex-column flex-wrap justify-content-start p-4">
            
            <!-- Tabs Filter -->
            <ul class="nav nav-pills nav-sm" id="myTab" role="tablist">
                <li class="nav-item me-2" role="presentation">
                    <button class="nav-link d-flex align-items-center active" id="alignment-tab" data-bs-toggle="tab" data-bs-target="#alignment-tab-pane" type="button" role="tab" aria-controls="alignment-tab-pane" aria-selected="true" @click="usingCustomFilters = false">
                        <i class="flaticon-flash me-1 mt-1 align-middle"></i> 
                        Quick Filters
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link d-flex align-items-center" id="custom-tab" data-bs-toggle="tab" data-bs-target="#custom-tab-pane" type="button" role="tab" aria-controls="custom-tab-pane" aria-selected="false" @click="usingCustomFilters = true">
                        <i class="flaticon-settings me-1 mt-1 align-middle"></i>
                        Advanced Filtering  
                    </button>
                </li>
            </ul>
            <div class="tab-content mt-20 bg-gray pt-25 ps-25 pe-25 pb-25" id="myTabContent">
                <!-- Status Filter -->
                <span class="fw-semibold mx-2 mb-5 fs-6">Status</span>
                <div class="mx-2 mb-25 d-flex align-items-center">
                    <div class="btn-group btn-group-sm mt-1" role="group">
                        <label class="btn btn-outline-primary btn-sm filter-button" v-for="option in filters.STATUS"
                            :key="option.value" :class="{ 'active': tempGlobalFilters.STATUS.includes(option.value) }">
                            <input type="checkbox" :value="option.value" v-model="tempGlobalFilters.STATUS" /> {{ option.label
                            }}
                        </label>
                    </div>
                    <button type="button" class="btn btn-link btn-sm" @click="selectAllStatus">Select All</button>
                    <button type="button" class="btn btn-link btn-sm" @click="deselectAllStatus">Deselect All</button>
                </div>
                <div class="tab-pane fade show active" id="alignment-tab-pane" role="tabpanel" tabindex="0">
                    <span class="fw-semibold mx-2 mb-5 fs-6">Alignment</span>
                    <div class="mx-2 mb-2 d-flex align-items-center">
                        <div class="btn-group btn-group-sm mt-1" role="group">
                            <label class="btn btn-outline-primary btn-sm filter-button" v-for="option in filters.ALIGNMENT"
                                :key="option.value" :class="{ 'active': tempAlignmentFilters.ALIGNMENT.includes(option.value) }">
                                <input type="checkbox" :value="option.value" v-model="tempAlignmentFilters.ALIGNMENT" /> {{ option.label
                                }}
                            </label>
                        </div>
                        <button type="button" class="btn btn-link btn-sm ms-2" @click="selectAllAlignment">Select All</button>
                        <button type="button" class="btn btn-link btn-sm" @click="deselectAllAlignment">Deselect All</button>
                    </div>
                </div>
                <div class="tab-pane fade" id="custom-tab-pane" role="tabpanel" tabindex="0">
                    <!-- Gap Closing Filter -->
                    <span class="fw-semibold mx-2 mb-5 fs-6">Gap Closing</span>
                    <div class="mx-2 mb-20 d-flex align-items-center">
                        <div class="btn-group btn-group-sm mt-1" role="group">
                            <label class="btn btn-outline-primary btn-sm filter-button" v-for="option in filters.GCI"
                                :key="option.value" :class="{ 'active': tempCustomFilters.GCI.includes(option.value) }">
                                <input type="checkbox" :value="option.value" v-model="tempCustomFilters.GCI" /> {{ option.label
                                }}
                            </label>
                        </div>
                        <button type="button" class="btn btn-link btn-sm ms-2" @click="selectAllGCI">Select All</button>
                        <button type="button" class="btn btn-link btn-sm" @click="deselectAllGCI">Deselect All</button>
                    </div>
                    <!-- STAGE Filter -->
                    <span class="mt-10 fw-semibold mx-2 mb-5 fs-6">Stage</span>
                    <div class="mx-2 mb-20 d-flex align-items-center">
                        <div class="btn-group btn-group-sm mt-1" role="group">
                            <label class="btn btn-outline-primary btn-sm filter-button" v-for="option in filters.STAGE"
                                :key="option.value" :class="{ 'active': tempCustomFilters.STAGE.includes(option.value) }">
                                <input type="checkbox" :value="option.value" v-model="tempCustomFilters.STAGE" /> {{ option.label }}
                            </label>
                        </div>
                        <button type="button" class="btn btn-link btn-sm ms-2" @click="selectAllStages">Select All</button>
                        <button type="button" class="btn btn-link btn-sm" @click="deselectAllStages">Deselect All</button>
                    </div>
                    <!-- Customer Type Filter -->
                    <span class="fw-semibold mx-2 mb-5 fs-6">Customer Type</span>
                    <div class="mx-2 mb-20 d-flex align-items-center">
                        <div class="btn-group btn-group-sm mt-1" role="group">
                            <label class="btn btn-outline-primary btn-sm filter-button" v-for="option in filters.MARTYPE"
                                :key="option.value" :class="{ 'active': tempCustomFilters.MARTYPE.includes(option.value) }">
                                <input type="checkbox" :value="option.value" v-model="tempCustomFilters.MARTYPE" /> {{
                                    option.label }}
                            </label>
                        </div>
                        <button type="button" class="btn btn-link btn-sm ms-2" @click="selectAllMarTypes">Select All</button>
                        <button type="button" class="btn btn-link btn-sm" @click="deselectAllMarTypes">Deselect All</button>
                    </div>
                    <!-- Company Segments Filter -->
                    <div class="mx-2 mb-2">
                        <div class="mb-2 d-flex align-items-center">
                            <span class="fw-semibold fs-6">Segments</span>
                            <button type="button" class="btn btn-link btn-sm" @click="selectAllSegments">Select All</button>
                            <button type="button" class="btn btn-link btn-sm" @click="deselectAllSegments">Deselect All</button>
                        </div>
                        <div class="btn-group btn-group-sm flex-wrap" role="group">
                            <label class="btn btn-outline-primary btn-sm filter-button"
                                v-for="option in filters.SEGMENT" :key="option.value"
                                :class="{ 'active': tempCustomFilters.SEGMENT.includes(option.value) }">
                                <input type="checkbox" :value="option.value" v-model="tempCustomFilters.SEGMENT" /> {{
                                    option.label }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-100 d-flex space-content">
                <!-- Apply Filters Button -->
                <div class="mt-4 pe-1 w-100 d-flex justify-content-end">
                    <button @click="applyFilters" class="btn btn-primary btn-sm d-flex align-items-center">          
                        Apply Filters
                        <i class="flaticon-next ms-1 mt-1 align-middle"></i> 
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import flatpickr from "flatpickr";
import { canAccessFeature } from "@/utils/featuresUtils"
import { filterService } from '@/api/filterService';

interface Filters {
    STATUS: Array<{ value: string, label: string }>;
    ALIGNMENT: Array<{ value: string, label: string }>;
    GCI: Array<{ value: string, label: string }>;
    STAGE: Array<{ value: string, label: string }>;
    MARTYPE: Array<{ value: string, label: string }>;
    SEGMENT: Array<{ value: string, label: string }>;
}

interface SelectedCustomFilters {
    GCI: string[];
    STAGE: string[];
    MARTYPE: string[];
    SEGMENT: string[];
}
interface SelectedAlignmentFilters {
    ALIGNMENT: string[],
}

interface SelectedGlobalFilters {
    STATUS: string[];
    STARTDATE: string;
    ENDDATE: string;
}

interface FilteredAppliedFilter {
    category: string;
    labels: string[];
}

export default defineComponent({
    name: "CompaniesToolbar",
    props: {
        isPrecisionPicks:  {
            type: Boolean,
            default: false,
        },
        isActionsEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const today = new Date();
        const defaultDayRange = 30;
        const pastDate = new Date(today.setDate(today.getDate() - defaultDayRange));

        return {
            canAccessFeature,
            isGridView: false,
            isCollapsed: true,
            usingCustomFilters: false,
            selectedStatus: "PASD",
            filters: {
                STATUS: [
                    { value: 'NEW', label: 'New' },
                    { value: 'PASD', label: 'Passed' },
                    { value: 'INPRG', label: 'In Progress' },
                    { value: '1STMT', label: '1st Meeting' },
                    { value: '2NDMT', label: '2nd Meeting' },
                    { value: 'INVST', label: 'Invested' },
                ],
                ALIGNMENT: [
                    { value: 'on-target', label: 'On Target' },
                    { value: 'on-fence', label: 'On The Fence' },
                    { value: 'not-closing', label: 'Not Gap Closing' },
                    { value: 'outside', label: 'Outside' },
                ],
                GCI: [
                    { value: 'gap-closing', label: 'Gap-Closing' },
                    { value: 'non-gap-closing', label: 'Non Gap-Closing' },
                ],
                STAGE: [
                    { value: 'Pre Seed Round', label: 'Pre Seed' },
                    { value: 'Seed Round', label: 'Seed' },
                    { value: 'Series A Round', label: 'Series A' },
                    { value: 'Series B Round', label: 'Series B' },
                    { value: 'Series C Round', label: 'Series C' },
                ],
                MARTYPE: [
                    { value: 'B2B', label: 'B2B' },
                    { value: 'B2C', label: 'B2C' },
                    { value: 'B2G', label: 'B2G' },
                    { value: 'B2Pro', label: 'B2Pro' },
                ],
                SEGMENT: [
                    { value: 'AI', label: 'AI' },
                    { value: 'Aerospace', label: 'Aerospace' },
                    { value: 'Agtech', label: 'Agtech' },
                    { value: 'Automotive', label: 'Automotive' },
                    { value: 'Beauty', label: 'Beauty' },
                    { value: 'Biotech', label: 'Biotech' },
                    { value: 'Blockchain', label: 'Blockchain' },
                    { value: 'Clean Tech', label: 'Clean Tech' },
                    { value: 'Computing', label: 'Computing' },
                    { value: 'Construction Tech', label: 'Construction Tech' },
                    { value: 'Consulting Services', label: 'Consulting Services' },
                    { value: 'Culinary', label: 'Culinary' },
                    { value: 'Cybersecurity Tech', label: 'Cybersecurity Tech' },
                    { value: 'Edu-tech', label: 'Edu-tech' },
                    { value: 'Elder Tech', label: 'Elder Tech' },
                    { value: 'Energy', label: 'Energy' },
                    { value: 'Entertainment', label: 'Entertainment' },
                    { value: 'Fashion', label: 'Fashion' },
                    { value: 'Fintech', label: 'Fintech' },
                    { value: 'Fitness', label: 'Fitness' },
                    { value: 'Food Tech', label: 'Food Tech' },
                    { value: 'Future of Work', label: 'Future of Work' },
                    { value: 'HR Tech', label: 'HR Tech' },
                    { value: 'Health', label: 'Health' },
                    { value: 'Healthcare', label: 'Healthcare' },
                    { value: 'Insurance Tech', label: 'Insurance Tech' },
                    { value: 'IoT', label: 'IoT' },
                    { value: 'Legal Tech', label: 'Legal Tech' },
                    { value: 'Logistics', label: 'Logistics' },
                    { value: 'Manufacturing', label: 'Manufacturing' },
                    { value: 'Marketplace', label: 'Marketplace' },
                    { value: 'Media Tech', label: 'Media Tech' },
                    { value: 'Mobile Tech', label: 'Mobile Tech' },
                    { value: 'Nanotechnology', label: 'Nanotechnology' },
                    { value: 'Networking', label: 'Networking' },
                    { value: 'Pet Tech', label: 'Pet Tech' },
                    { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
                    { value: 'Productivity', label: 'Productivity' },
                    { value: 'Real Estate', label: 'Real Estate' },
                    { value: 'Restaurant', label: 'Restaurant' },
                    { value: 'Retail', label: 'Retail' },
                    { value: 'Robotics', label: 'Robotics' },
                    { value: 'SAAS', label: 'SAAS' },
                    { value: 'Smart Home', label: 'Smart Home' },
                    { value: 'Social Media', label: 'Social Media' },
                    { value: 'Space Tech', label: 'Space Tech' },
                    { value: 'Supply Chain', label: 'Supply Chain' },
                    { value: 'Travel & Hospitality', label: 'Travel & Hospitality' },
                    { value: 'Video Games', label: 'Video Games' },
                    { value: 'Wearable Tech', label: 'Wearable Tech' },
                    { value: 'Wellness', label: 'Wellness' },
                    { value: 'Women Tech', label: 'Women Tech' },
                    { value: 'eCommerce', label: 'eCommerce' },
                ],
            } as Filters,
            selectedCustomFilters: {
                GCI: [],
                STAGE: [],
                MARTYPE: [],
                SEGMENT: [],
            } as SelectedCustomFilters,
            tempCustomFilters: {
                GCI: [],
                STAGE: [],
                MARTYPE: [],
                SEGMENT: [],
            } as SelectedCustomFilters,
            selectedAlignmentFilters: {
                ALIGNMENT: [],
            } as SelectedAlignmentFilters,
            tempAlignmentFilters: {
                ALIGNMENT: [],
            } as SelectedAlignmentFilters,
            selectedGlobalFilters: {
                STATUS: [],
                STARTDATE: new Date(pastDate.setHours(0,0,0,0)).toISOString(),
                ENDDATE: new Date(new Date().setHours(0,0,0,0)).toISOString(),
            } as SelectedGlobalFilters,
            tempGlobalFilters: {
                STATUS: [],
                STARTDATE: new Date(pastDate.setHours(0,0,0,0)).toISOString(),
                ENDDATE: new Date(new Date().setHours(0,0,0,0)).toISOString(),
            } as SelectedGlobalFilters,
            onThesisFilters: {
                STAGE: [],
                MARTYPE: [],
                SEGMENT: [],
                GCI: [],
            } as SelectedCustomFilters,
        };
    },
    computed: {
        filteredAppliedFilters(): FilteredAppliedFilter[] {
            return Object.entries(this.selectedCustomFilters)
                .map(([category, values]) => ({
                    category,
                    labels: (values as string[]).map((value) => this.getFilterLabel(category, value)),
                }))
                .filter((filter) => filter.labels.length > 0);
        },
    },
    methods: {
        toggleView() {
            this.isGridView = !this.isGridView;
            this.$emit('view-change', this.isGridView);
        },
        applyFilters() {
            const tempFilters = this.usingCustomFilters ? this.tempCustomFilters : this.tempAlignmentFilters;
            const tempGlobalFilters = this.tempGlobalFilters;

            this.toggleCollapse(false, true),

            this.$emit('filters-applied', tempFilters, tempGlobalFilters, () => {
                this.selectedGlobalFilters = { ...this.tempGlobalFilters };

                if (this.usingCustomFilters) {
                    this.selectedCustomFilters = { ...this.tempCustomFilters };
                } else {
                    this.selectedAlignmentFilters = { ...this.tempAlignmentFilters };
                }
            });
        },
        toggleCollapse(reset=true, forceClose=false) {
            if (forceClose) {
                this.isCollapsed = true;
            } else {
                this.isCollapsed = !this.isCollapsed;
            }

            if (this.isCollapsed && reset) {
                this.tempCustomFilters = { ...this.selectedCustomFilters };
                this.tempAlignmentFilters = { ...this.selectedAlignmentFilters };
            } else if (!this.isCollapsed) {
                this.setActiveTab();
            }
        },
        getFilterLabel(category: string, value: string) {
            const options = this.filters[category] || [];
            const option = options.find((opt) => opt.value === value);
            return option ? option.label : value;
        },
        setAllFilters() {
            const allCustomFilters = {
                STAGE: [],
                MARTYPE: [],
                SEGMENT: [],
                GCI: [],
            } as SelectedCustomFilters

            const allAlignmentFilters = {
                ALIGNMENT: [],
            } as SelectedAlignmentFilters

            const allGlobalFilters = {
                STATUS: [],
                STARTDATE: this.tempGlobalFilters.STARTDATE,
                ENDDATE: this.tempGlobalFilters.ENDDATE,
            } as SelectedGlobalFilters
            
            for (const [category, values] of Object.entries(this.filters)) {
                if (category === 'STATUS') {
                    for (const value of values) {
                        if (category in allGlobalFilters) {
                            allGlobalFilters['STATUS'].push(value.value);
                        } else {
                            allGlobalFilters['STATUS'] = [value.value];
                        }
                    }
                } else if (category === 'ALIGNMENT') {
                    for (const value of values) {
                        if (category in allAlignmentFilters) {
                            allAlignmentFilters[category].push(value.value);
                        } else {
                            allAlignmentFilters[category] = [value.value];
                        }
                    }

                } else {
                    for (const value of values) {
                        if (category in allCustomFilters) {
                            allCustomFilters[category].push(value.value);
                        } else {
                            allCustomFilters[category] = [value.value];
                        }
                    }
                }
            }
            
            this.tempCustomFilters = { ...allCustomFilters };
            this.tempAlignmentFilters = { ...allAlignmentFilters };
            this.tempGlobalFilters = { ...allGlobalFilters };
        },
        selectAllAlignment() {
            this.tempAlignmentFilters.ALIGNMENT = this.filters.ALIGNMENT.map(option => option.value);
        },
        deselectAllAlignment() {
            this.tempAlignmentFilters.ALIGNMENT = [];
        },
        selectAllStatus() {
            this.tempGlobalFilters.STATUS = this.filters.STATUS.map(option => option.value);
        },
        deselectAllStatus() {
            this.tempGlobalFilters.STATUS = [];
        },
        selectAllGCI() {
            this.tempCustomFilters.GCI = this.filters.GCI.map(option => option.value);
        },
        deselectAllGCI() {
            this.tempCustomFilters.GCI = [];
        },
        selectAllStages() {
            this.tempCustomFilters.STAGE = this.filters.STAGE.map(option => option.value);
        },
        deselectAllStages() {
            this.tempCustomFilters.STAGE = [];
        },
        selectAllMarTypes() {
            this.tempCustomFilters.MARTYPE = this.filters.MARTYPE.map(option => option.value);
        },
        deselectAllMarTypes() {
            this.tempCustomFilters.MARTYPE = [];
        },
        selectAllSegments() {
            this.tempCustomFilters.SEGMENT = this.filters.SEGMENT.map(option => option.value);
        },
        deselectAllSegments() {
            this.tempCustomFilters.SEGMENT = [];
        },
        setActiveTab() {
            this.$nextTick(() => {
                if (this.usingCustomFilters) {
                    document.getElementById('custom-tab')?.classList.add('active');
                    document.getElementById('custom-tab-pane')?.classList.add('active');
                    document.getElementById('custom-tab-pane')?.classList.add('show');

                    document.getElementById('alignment-tab')?.classList.remove('active');
                    document.getElementById('alignment-tab-pane')?.classList.remove('active');
                } else {
                    document.getElementById('alignment-tab')?.classList.add('active');
                    document.getElementById('alignment-tab-pane')?.classList.add('active');
                    document.getElementById('alignment-tab-pane')?.classList.add('show');
                    
                    document.getElementById('custom-tab')?.classList.remove('active');
                    document.getElementById('custom-tab-pane')?.classList.remove('active');
                }
            });
        },
        callStatusUpdateFunction() {
            this.$emit('status-update', this.selectedStatus)
        }
    },
    async mounted() {
        const today = new Date();
        const defaultDayRange = 30;
        const pastDate = new Date(today.setDate(today.getDate() - defaultDayRange));
        
        flatpickr("#timeline", {
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d",
            defaultDate: [pastDate, new Date()],
            mode: "range",
            onClose: (selectedDates) => {
                const startDate = new Date(selectedDates[0].setHours(0,0,0,0)).toISOString();
                const endDate = new Date(selectedDates[1].setHours(0,0,0,0)).toISOString();

                if (startDate !== this.tempGlobalFilters.STARTDATE || endDate !== this.tempGlobalFilters.ENDDATE) {   
                    this.tempGlobalFilters.STARTDATE = startDate;
                    this.tempGlobalFilters.ENDDATE = endDate;

                    this.applyFilters();
                }
                
            }
        });

        // Populate filters
        this.setAllFilters();
        
        this.selectedCustomFilters = { ...this.tempCustomFilters };
        this.selectedAlignmentFilters = { ...this.tempAlignmentFilters };
        this.selectedGlobalFilters = { ...this.tempGlobalFilters };

        // Company Thesis Filters
        const companyFiltersResponse = await filterService.getCompanyFilters()
        const companyThesisFilters = companyFiltersResponse.data;
        
        // Transform API company filter response to desired format
        const onThesisFilters = {};
        for (const filter of companyThesisFilters) {
            const field = filter.field;
            onThesisFilters[field] = filter.value;
        }

        // Apply API response filters
        for (const [category, values] of Object.entries(onThesisFilters)) {
            if (category in this.tempCustomFilters) {
                (this.onThesisFilters as any)[category] = values;
            }
        }
    },
});
</script>

<style scoped>
.container-fluid {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 25px;
    width: calc(100% - 24px);
    max-width: 1296px;
}

.btn-group input[type="checkbox"] {
    display: none;
}

.btn-group label {
    cursor: pointer;
    margin-right: 2px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.btn-group input[type="checkbox"]:checked+label,
.btn-group label.active {
    /* background-color: #0d6efd;
    border-color: #0d6efd; */
    color: white;
}

.btn-group input[type="checkbox"],
.btn-group label {
    /* color: #0d6efd;
    border-color: #0d6efd */
}

.btn-group input[type="checkbox"]:hover,
.btn-group label:hover {
    color: white;
    border-color: #8faddb;
    background-color: #8faddb;
}

.btn-link.btn-sm {
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 5px;
}

.filter-button {
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.nav.nav-sm {
    --bs-nav-link-padding-x: 15px;
    --bs-nav-link-padding-y: 5px;
    --bs-nav-link-font-size: 15px
}

.select-calendar {
    width: 275px !important;
}

.applied-filters {
    border: 1px solid #dee2e6;
}
</style>