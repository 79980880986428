<template>
  <template v-if="company.descriptors">
    <span v-for="(descriptor, index) in company.descriptors" :key="descriptor" :class="descriptorBadgeClass(company.in_thesis, company.gap_closing_score)">
      <template v-if="index < 6">{{ descriptor }}</template>
      <template v-else-if="index === 6">...</template>
    </span>
  </template>
</template>

<script>
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'
import { getDescriptorBadgeClass } from "@/utils/layoutUtils";

export default {
  props: ['company'],
  emits: companiesEmits,
  methods: {
    descriptorBadgeClass(alignment, score) {
      return getDescriptorBadgeClass(alignment, score);
    }
  }
};
</script>