<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Forgot Password?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
            Enter your email address below and we will send you the password reset instructions
          </p>
          <form @submit.prevent="handleSubmit">
            <div class="form-group mb-15 mb-sm-20">
              <label class="d-block text-black fw-semibold mb-10" for="email">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                v-model="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. youremail@kapor.ai"
                required
              />
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? 'Sending...' : 'Submit' }}
            </button>
            <div v-if="message" class="mt-3 text-center" :class="{'text-success': !error, 'text-danger': error}">
              {{ message }}
            </div>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/login"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
              >
                <i class="ph-bold ph-arrow-left"></i>
                Back To Sign In
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <LegalLinks />
</template>

<script>
import { ref } from 'vue';
import { userService } from '@/api/userService';
import LegalLinks from '@/components/Common/LegalLinks.vue'

export default {
  name: "ForgotPassword",
  components: {
    LegalLinks
  },
  setup() {
    const email = ref('');
    const isSubmitting = ref(false);
    const message = ref('');
    const error = ref(false);

    const handleSubmit = async () => {
      isSubmitting.value = true;
      message.value = '';
      error.value = false;

      try {
        await userService.passwordResetRequest(email.value);
        message.value = 'Password reset instructions have been sent to your email.';
        email.value = ''; // Clear the email input after successful submission
      } catch (err) {
        error.value = true;
        message.value = 'An error occurred. Please try again later.';
        console.error('Password reset request error:', err);
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      email,
      isSubmitting,
      message,
      error,
      handleSubmit
    };
  }
};
</script>