<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-25 pb-25 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30" v-if="isDataReady && company?.thesis_failed_filters?.totals.applied_filters > 0  && company?.thesis_failed_filters?.totals.applied_filters != null && company?.gap_closing_category?.AVG.score != 0 && company?.gap_closing_category?.AVG.score != null" >
        <h5 class="card-title fw-bold mb-0">Pre-screening: 
            <span v-if="(company?.thesis_failed_filters?.totals?.hard_fails > 0 || failedFilters.length > 0) && company?.thesis_failed_filters?.totals.applied_filters > 0">Failed</span>
            <span v-else-if="company?.thesis_failed_filters?.totals?.soft_fails > 0">Partially Passes</span>
            <span v-else>Passes</span>
        </h5>
        <div v-if="isChartDataValid" id="scorecard">
            <apexchart type="treemap" :height="chartHeight" :options="chartOptions" :series="series" :key="chartKey"></apexchart>
        </div>
        <div v-if="failedFilters && failedFilters.length > 0 && chartReady" class="mt-4">
            <p class="fw-bold mb-2">Failed Criteria: {{ failedFilters.length }} of {{ appliedFilters }}</p>
            <div class="row">
                <div v-for="filter in failedFilters" :key="filter.name" :class="columnClass" class="mb-10">
                    <div class="card mb-5 border-0 rounded-0 bg-light stats-box h-100">
                        <div class="card-body p-5">
                            <div class="d-flex">
                                <div>
                                    <div class="icon position-relative rounded-circle text-center text-danger w-30">
                                        <i :class="getIconName(filter.name)"></i>
                                     </div>
                                </div>
                                
                                <div class="title pt-3 pb-3 pe-15">
                                    <p class="fw-black mb-1 lh-1">
                                        {{ getFilterDisplayName( filter.name ) }}
                                    </p>
                                    <div class="d-block mb-0 fs-13 fw-medium text-dark-emphasis" v-html="filter.reason"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['company'],
    computed: {
        hasFailedFilters() {
            return this.failedFilters && this.failedFilters.length > 0;
        },
        failedFiltersCount() {
            return this.failedFilters ? this.failedFilters.length : 0;
        },
        totalFiltersCount() {
            return (this.company?.thesis_failed_filters?.totals?.applied_filters || 0) + 1;
        },
        isGapClosingPassing() {
            return this.company?.gap_closing_category?.POT.score >= 7;
        },
        totalFilters() {
            return this.company?.thesis_failed_filters?.totals?.applied_filters + 1; 
        },
        columnClass() {
            const count = this.failedFilters.length;
            if (count === 1) return 'col-12';
            if (count === 2) return 'col-md-6';
            return 'col-md-6';
        },
        failedFilters() {
            const filters = [];
            
            const gapClosingAVG = this.company?.gap_closing_category?.AVG.score;
            if (gapClosingAVG !== undefined && gapClosingAVG < 4) {
                filters.push({
                    name: 'Gap Closing',
                    reason: `Concerns about impact.`
                });
            }            
            if (this.company?.thesis_failed_filters?.hard_fails) {
                this.company.thesis_failed_filters.hard_fails.forEach(fail => {
                    if (fail.field) {
                        let uniqueActual = Array.isArray(fail.actual) 
                            ? [...new Set(fail.actual)].join(', ')
                            : fail.actual;
                        
                        let expectedValue = Array.isArray(fail.expected)
                            ? fail.expected.join(', ')
                            : fail.expected;

                        filters.push({
                            name: fail.field,
                            reason: `Is ${uniqueActual} not ${expectedValue}.`
                        });
                    }
                });
            }
            if ( this.company?.customAnswers) {
                this.company.customAnswers.forEach(answer => {
                    if ( !answer.answer_type ) return;
                       switch(answer.answer_type ) {
                        case "UACC":
                            filters.push({
                                    name: answer.custom_question_data.key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                                    reason: `<strong class="text-black">${answer.answer}.</strong> ${answer.proof}`
                                });
                            break;
                        }
                    
                
                });
            }
            
            return filters;
        },
        isChartDataValid() {
            return this.series.every(item => Array.isArray(item.data) && item.data.length > 0);
        }
    },
    data: function () {
        return {
            isDataReady: false,
            chartHeight: 110,
            chartKey: 0,
            chartReady: false,
            appliedFilters: 1, // Gap-Closing is always applied
            series: [
                { name: 'Failed', data: [] },
                { name: 'Research', data: [] },
                { name: 'Passed', data: [] }
            ],
            chartOptions: {
                chart: {
                    type: 'treemap',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'left',
                    offsetY: 6, 
                    fontSize: '12px',
                    fontWeight: 500,
                    fontFamily: "Red Hat Display, sans-serif",
                    markers: {
                        width: 10,
                        height: 10,
                        radius: 5
                    },
                },
                colors: ['#DC3545', '#FFC107', '#198754'], 
                dataLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 700,
                        fontSize: '12px',
                        fontFamily: "Red Hat Display, sans-serif",
                    },
                    offsetY: -1,
                    formatter: function(text, op) {
                        return [text]
                    },
                },
                plotOptions: {
                    treemap: {
                        enableShades: false,
                        distributed: false,
                        colorScale: {
                            ranges: [{
                                from: 0,
                                to: Number.MAX_SAFE_INTEGER,
                                color: undefined,
                                foreColor: undefined,
                                opacity: 1
                            }]
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {

                            const reason = w.config.series[seriesIndex].data[dataPointIndex].reason;
                            const seriesName = w.config.series[seriesIndex].name;

                            if ( reason ) {
                                return `${reason}`;
                            } else {
                                return `${seriesName}`;
                            }
                        }
                    },
                    cssClass: 'apexcharts-tooltip-custom'
                },
            },
        };
    },
    mounted() {
        this.resetData();
        this.updateSeriesData();
    },
    beforeUnmount() {
        this.resetData();
    },
    watch: {
        'company.gap_closing_category.CUR.score': {
            handler() {
                this.resetData();
                this.updateSeriesData();
            },
            deep: true
        },
        'company.thesis_failed_filters.totals': {
            handler() {
                this.resetData();
                this.updateSeriesData();
            },
            deep: true
        },
    },
    methods: {
        resetData() {
            this.isDataReady = false;
            this.chartKey = 0;
            this.chartReady = false;
            this.appliedFilters = 1;
            this.series = [
                { name: 'Failed', data: [] },
                { name: 'Research', data: [] },
                { name: 'Passed', data: [] }
            ];
        },
        updateSeriesData() {     
            if (this.company && this.company.thesis_failed_filters && this.company.thesis_failed_filters.applied_filters) {
                const passedData = [];
                const unknownData = [];
                const failedData = [];

                if ( this.company.thesis_failed_filters.totals.applied_filters) {
                    this.appliedFilters = this.company.thesis_failed_filters.totals.applied_filters + 1;
                }

                // Add Gap-Closing filter
                const gapClosingAVG = this.company?.gap_closing_category?.AVG.score;
                const gapClosingDataPoint = {
                    x: 'Gap-Closing',
                    y: 1
                };

                if (gapClosingAVG >= 7) {
                    passedData.push(gapClosingDataPoint);
                } else if (gapClosingAVG >= 6) {
                    gapClosingDataPoint.reason = `Concerns about impact.`;
                    unknownData.push(gapClosingDataPoint);
                } else {
                    gapClosingDataPoint.reason = `Concerns about impact.`;
                    failedData.push(gapClosingDataPoint);
                    this.failedFilters.push({
                        name: 'Gap Closing',
                        reason: `Concerns about impact.`
                    });
                }

                // Process custom Answers
                if ( this.company?.customAnswers) {
                    this.company.customAnswers.forEach(answer => {
                   
                   const field = answer.custom_question_data.key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').substring(0, 15) + (answer.custom_question_data.key.length > 15 ? '...' : '');
                   const dataPoint = {
                       x: field,
                       y: 1
                   };

                   if ( answer.answer_type ) {
                      
                       switch(answer.answer_type) {
                           case "UACC":
                               failedData.push(dataPoint);
                               this.appliedFilters++;
                               break;
                           case "ACC":
                               passedData.push(dataPoint);
                               this.appliedFilters++;
                               break;
                           case "NEI":
                                unknownData.push(dataPoint);
                                this.appliedFilters++;
                                break;
                            }

                        }
                    });
                }
                

                // Process other filters
                this.company.thesis_failed_filters.applied_filters.forEach(filter => {
                    const status = this.getFilterStatus(filter);
                    const reason = this.getFilterReason(filter);
                    const dataPoint = {
                        x: this.getFilterDisplayName(filter),
                        y: 1,
                        reason: reason
                    };

                    switch(status) {
                        case 'Passed':
                            passedData.push(dataPoint);
                            break;
                        case 'Needs Analysis':
                            unknownData.push(dataPoint);
                            break;
                        case 'Failed':
                            failedData.push(dataPoint);
                            break;
                    }
                });

                this.series = [
                    { name: 'Failed', data: failedData },
                    { name: 'Needs Analysis', data: unknownData },
                    { name: 'Passed', data: passedData }
                ];

                // Ensure that each series has at least one data point
                this.series.forEach(item => {
                    if (item.data.length === 0) {
                        item.data.push({ x: 'No Data', y: 0 });
                    }
                });

                this.isDataReady = true;

                this.$nextTick(() => {
                    this.chartReady = true;
                    this.chartKey += 1;
                });
            } else {
                // If data is not available, set default empty data
                this.isDataReady = false;
                this.series = [
                    { name: 'Passed', data: [{ x: 'No Data', y: 0 }] },
                    { name: 'Needs Analysis', data: [{ x: 'No Data', y: 0 }] },
                    { name: 'Failed', data: [{ x: 'No Data', y: 0 }] }
                ];
            }
        },
        getFilterStatus(filter) {
            if (!this.company || !this.company.thesis_failed_filters) return 'Needs Analysis';
            const hardFail = this.company.thesis_failed_filters.hard_fails.find(f => f.field === filter);
            const softFail = this.company.thesis_failed_filters.soft_fails.find(f => f.field === filter);
            
            if (hardFail) return 'Failed';
            if (softFail) return 'Needs Analysis';
            return 'Passed';
        },
        getFilterReason(filter) {
            if (!this.company || !this.company.thesis_failed_filters) return "Couldn't determine";
  
            const hardFail = this.company.thesis_failed_filters.hard_fails.find(f => f.field === filter);
            const softFail = this.company.thesis_failed_filters.soft_fails.find(f => f.field === filter);

            if (!hardFail && !softFail) return 'Passed';

            let reason;
            const fail = hardFail || softFail;

            if (hardFail) {
                reason = "Failed, expected";
            } else {
                reason = "Couldn't determine";
            }

            if (Array.isArray(fail.expected) && fail.expected.length > 0) {
                const expectedList = fail.expected.join(' or ');
                reason += ` ${fail.field === 'COUNTRYCODE' ? 'if in' : 'if is'} ${expectedList}`;
            }

            if (fail.actual !== null && fail.actual !== undefined && fail.actual !== '') {
                const actualValue = Array.isArray(fail.actual) ? fail.actual.join(', ') : fail.actual;
                reason += `. Research found: ${actualValue}`;
            }

            return reason;
        },
        getFilterDisplayName(filter) {
            const filterMap = {
                COUNTRYCODE: 'Location',
                STAGE: 'Stage',
                SEGMENT: 'Segment',
                PRODTYPE: 'Product Type',
                REVENUEMODEL: 'Revenue Model',
                MARTYPE: 'Business Model',
                'Gap-Closing': 'Gap-Closing'
            };
            return filterMap[filter] || filter;
        },
        getFilterActual(filter) {
            const fail = [...this.company.thesis_failed_filters.hard_fails, ...this.company.thesis_failed_filters.soft_fails]
                .find(f => f.field === filter);
            
            if (fail) {
                return Array.isArray(fail.actual) ? fail.actual.join(', ') : fail.actual || 'No data';
            }
            return 'Passed';
        },
        getIconName(filter) {
            const iconMap = {
                STAGE: 'flaticon-industry',
                SEGMENT: 'flaticon-status',
                COUNTRYCODE: 'flaticon-maps-and-flags',
                PRODTYPE: 'flaticon-price-tag-1',
                REVENUEMODEL: 'flaticon-sterile-box',
            };
            return iconMap[filter] || 'flaticon-sterile-box';
        }
    }
};
</script>

<style>
.apexcharts-tooltip-custom {
  word-wrap: break-word;
  white-space: normal !important;
  overflow: visible !important;
}
.apexcharts-tooltip-custom .apexcharts-tooltip-marker {
    display: none;
}
.apexcharts-tooltip-custom .apexcharts-tooltip-text-y-label {
    font-weight: 700;
}
.apexcharts-tooltip-custom .apexcharts-tooltip-text-y-value {
    margin-left: 0px !important;
    font-weight: 500;
}
#scorecard text {
    font-size: 10px;
}
@media (min-width: 768px) {
    #scorecard text {
        font-size: 10px;
    }
}
@media (min-width: 992px) {
    #scorecard text {
        font-size: 11px;
    }
}
.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series, .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    display: flex !important;
    align-items: center !important;
}
</style>