<template>
    <span v-if="status === 'Passed'" class="fs-13">
      No Predictions: You Passed
    </span>
    <span v-else-if="status === 'Invested'" class="fs-13">
      No Predictions: You Invested
    </span>
    <span :class="descriptorBadgeClass" v-else-if="customAnswerFails && inThesis !== null && gapClosingScore !== null" tabindex="0" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover focus" :data-bs-content="generateHardFailContent()" class="popover-content">
      Pass:  
      {{ formattedHardFailReasons }}
    </span>
    <span :class="descriptorBadgeClass" v-else-if="hasHardFails && inThesis !== null && gapClosingScore !== null"  tabindex="0" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover focus" :data-bs-content="generateHardFailContent()" class="popover-content">
      Pass:   
      {{ formattedHardFailReasons }}
    </span>
    <span :class="descriptorBadgeClass" v-else-if="gapClosingScore < 6 && inThesis !== null && gapClosingScore !== null"  tabindex="0" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover focus" :data-bs-content="generateHardFailContent()" class="popover-content">
      Pass:   
      {{ formattedHardFailReasons }}
    </span>
    <span :class="descriptorBadgeClass" v-else-if="(gapClosingScore >= 6 && gapClosingScore < 7) && inThesis !== null && gapClosingScore !== null" tabindex="0" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover focus" :data-bs-content="generateHardFailContent()" class="popover-content">
      Research: 
      <template v-if="softPassReasons.length > 0">
        {{ formattedSoftPassReasons }}
      </template>
      <template v-else>
        Impact
      </template>
    </span>
    <span :class="descriptorBadgeClass" v-else-if="gapClosingScore !== null" tabindex="0" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover focus" :data-bs-content="generateHardFailContent()" class="popover-content">
      <template v-if="softPassReasons.length > 0">
        Research:
        {{ formattedSoftPassReasons }}
      </template>
      <template v-else>
        Confirm:
        Impact
      </template>
    </span>
    <span v-else-if="gapClosingScore === null && hasActiveFailures === true">
      <span class="badge w-100 bg-secondary">Manual Analysis Needed</span>
    </span>
  </template>
  
  <script lang="ts">
import { defineComponent, PropType, computed, ref} from "vue";
import { getDescriptorBadgeClass } from "@/utils/layoutUtils";

interface CustomAnswer {
  answer: string;
  answer_type: string;
  answer_type_label: string;
  proof: string | null;
  custom_question_data: {
    key: string;
  } | null;
}

export default defineComponent({
  name: "PredictedOutcome",
  props: {
    inThesis: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    gapClosingScore: {
      type: Number as PropType<number>,
      required: true,
    },
    reputationFlags: {
      type: Array as PropType<unknown[]>,
      required: false,
      default: () => [],
    },
    softFailedFilters: {
      type: Array as PropType<unknown[]>,
      required: false,
      default: () => [],
    },
    hardFailedFilters: {
      type: Array as PropType<unknown[]>,
      required: false,
      default: () => [],
    },
    status: {
      type: String as PropType<string>,
      required: true,
    },
    fullText: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    customAnswers: {
      type: Array as PropType<CustomAnswer[]>,
      required: false,
      default: () => [],
    },
    hasActiveFailures: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const hasHardFails = ref(props.hardFailedFilters.length > 0);  

    const customAnswerFails = props.customAnswers.filter(answer => 
        answer.answer_type === "UACC"
      ).length > 0;

    const softPassReasons = computed(() => {
      const filterMap = {
        COUNTRYCODE: 'geography',
        STAGE: 'stage',   
        PRODTYPE: 'product type',
        REVENUEMODEL: 'revenue model'     
      };
      return props.softFailedFilters?.map(fail => filterMap[(fail as any).field] || (fail as any).field) || [];
    });

    const getFilterName = (field: string) => {
      const filterMap = {
        COUNTRYCODE: 'Geography',
        MARTYPE: 'Market type',
        SEGMENT: 'Industry',
        STAGE: 'Stage',
        INDUSTRY: 'Industry',
        PRODTYPE: 'Product type',
        REVENUEMODEL: 'Revenue model',
        GAP_CLOSING: 'Gap-closing'
      };
      return filterMap[field as keyof typeof filterMap] || field;
    };

    const formatReasons = (reasons: any[], transformFn = (x: any) => x) => {
      if (!Array.isArray(reasons)) {
        return '';
      }
      return reasons.map((reason, index) => {
        let formattedReason = transformFn(reason.field || reason);
        
        if (index < reasons.length - 2) {
          formattedReason += ', ';
        } else if (index === reasons.length - 2) {
          formattedReason += ' & ';
        }
        
        return formattedReason;
      }).join('');
    };

    const truncateReason = (reason: string) => {
      if (props.fullText == true) return reason;

      const words = reason.split(' ');
      if (words.length > 2) {
        return words.slice(0, 3).join(' ') + '...';
      }
      return reason;
    };

    const addFailedCustomAnswers = (reasons: any[], softPass = false) => {
      if (props.customAnswers.length > 0) {
        props.customAnswers.forEach(answer => {
          if (answer.answer_type && answer.custom_question_data && answer.custom_question_data.key) {
            let formattedKey = answer.custom_question_data.key
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');

            switch(answer.answer_type) {
              case "NEI":
                if (softPass && !reasons.includes(formattedKey)) {
                  reasons.push(formattedKey);
                }
                break;
              case "UACC":
                hasHardFails.value = true;
                if (!reasons.includes(formattedKey)) {
                  reasons.push(formattedKey);
                }
                break;
            }
          }
        });
      }
      return reasons;
    };
   
    const addNotGapClosing = (reasons: any[]) => {
      if (props.gapClosingScore < 6 && !reasons.includes('Not Gap-Closing')) {
        reasons.push('Not Gap-Closing');
      }
      return reasons;
    };

    const addResearchImpact = (reasons: any[]) => {
      if (props.gapClosingScore < 7 && !reasons.includes('Not Gap-Closing')) {
        return [...reasons, 'Impact'];
      }
      return reasons;
    };

    const formattedHardFailReasons = computed(() => {
      const formatted = formatReasons(addFailedCustomAnswers(addNotGapClosing(props.hardFailedFilters), false) || [], getFilterName);
      return truncateReason(formatted);
    });

    const formattedSoftPassReasons = computed(() => {
      const formatted = formatReasons(addFailedCustomAnswers(addResearchImpact(props.softFailedFilters), true) || [], getFilterName);
      return truncateReason(formatted);
    });

    const descriptorBadgeClass = computed(() => {

      let in_thesis = props.inThesis;
      if (hasHardFails.value) {
        in_thesis = false;
      }
      let classes = getDescriptorBadgeClass(in_thesis, props.gapClosingScore);
      
      if (props.fullText) {
        classes['block-badge'] = true;
      }
      return classes;
    });

    const generateHardFailContent = () => {
      let gap_flag = false;
    
      if (props.gapClosingScore < 7) gap_flag = true;
    
      if (!props.hardFailedFilters && !props.softFailedFilters && !gap_flag) return '';
      
      let content = '<h6 class="fs-14">Predicted Next Steps:</h6> <ul class="m-0 p-0 ms-3 pb-2 fs-13">';
        
      if (props.hardFailedFilters?.length > 0 ) {
        content += props.hardFailedFilters.map((fail: any) => {
          // if undefined then skip
          if (fail.field === undefined) return '';

          let failContent = `<li>Pass: ${getFilterName(fail.field)} is `;
        
          if (Array.isArray(fail.actual)) {
            failContent += fail.actual.join(', ');
          } else {
            failContent += fail.actual;
          }
        
          failContent += ' not ';
    
          if (fail.field === 'INDUSTRY') {
            failContent += 'one of your preferred industries';
          } else if (fail.field === 'COUNTRYCODE') {
            failContent += 'one of your preferred countries';
          } else {  
            if (Array.isArray(fail.expected)) {
              if (fail.expected.length > 1) {
                failContent += fail.expected.slice(0, -1).join(', ') + ', or ' + fail.expected[fail.expected.length - 1];
              } else {
                failContent += fail.expected.join(', ');
              }
            } else {
              failContent += fail.expected;
            }
          }  
    
          failContent += '.</li>';
          
          return failContent;
        }).join('');
      }

      if (props.gapClosingScore < 6) content += '<li>Pass: Gap-closing report indicates limitations.</li>';
      if (props.gapClosingScore >= 6 && props.gapClosingScore < 7 && props.hardFailedFilters?.length === 0 ) content += '<li>Research: Impact, has potential.</li>';
      if (props.gapClosingScore >= 7 && props.hardFailedFilters?.length === 0) content += '<li>Confirm impact in diligence.</li>';

      if (props.softFailedFilters?.length > 0 && props.hardFailedFilters?.length <= 0 ) {
        content += props.softFailedFilters.map((fail: any) => {
          if (fail.field === undefined) return '';

          let failContent = `<li>Research ${getFilterName(fail.field)}.`;
          
          failContent += '</li>';
          
          return failContent;
        }).join('');
      }

      if (props.customAnswers.length > 0) {
        props.customAnswers.forEach(answer => {
          if (answer.answer_type && answer.custom_question_data && answer.custom_question_data.key) {
            let formattedKey = answer.custom_question_data.key
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            switch(answer.answer_type) {
              case "NEI":
              case "UACC":
                if (answer.answer_type === "NEI" && props.hardFailedFilters?.length == 0 ) {
                  content += `<li>Research ${formattedKey}.</li>`;
                } else if (answer.answer_type === "UACC") {
                  content += `<li>Pass: ${formattedKey}.</li>`;
                }
                break;
            }
          }
        });
      }
          
      content += '</ul>';
      
      return content;
    };

    return {
      hasHardFails,
      customAnswerFails,
      softPassReasons,
      formattedHardFailReasons,
      formattedSoftPassReasons,
      descriptorBadgeClass,
      generateHardFailContent,
      getFilterName,
      formatReasons,
      truncateReason
    };
  }
});
</script>

<style scoped>
.badge.block-badge {
    display: block;
    text-wrap: wrap;
    line-height:15px;
    text-align: left;
}
</style>