<template>
  <div class="flex-grow-1"></div>
  <footer
    class="footer-area text-center text-md-start bg-white ps-15 pe-15 pt-20 pb-20 ps-md-40 pe-md-40 pt-md-25 pb-md-25"
  >
    <div class="row align-items-center">
      <div class="col-md-6 col-lg-6">
        <p class="text-body-secondary fw-medium mb-0">
          © <span class="fw-bold">Kapor AI</span>. All rights
          reserved by
          <a
            href="https://kaporcenter.org/"
            class="text-decoration-none fw-bold transition"
            target="_blank"
            >Kapor Center</a
          >
          <!-- <a
            href="https://sidekick.kapor.ai/privacy.html"
            class="text-decoration-none fw-bold transition"
            target="_blank"
            >Privacy Policy</a
          >
          <a
            href="https://sidekick.kapor.ai/tos.html"
            class="text-decoration-none fw-bold transition"
            target="_blank"
            >Terms of Service</a
          > -->
        </p>
      </div>
      <div class="col-md-6 col-lg-6 text-md-end mt-5 mt-md-0">
        <router-link
          to="/logout"
          class="text-decoration-none fw-bold transition"
        >Logout</router-link>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
export default {
  name: "MainFooter",
};
</script>