<template>
    <div class="dropdown" v-if="company.gap_closing_score !== null">
        <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition" type="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <i class="flaticon-dots"></i>
        </button>
        <ul class="dropdown-menu">
            <router-link :to="'/company-report/?company_name='+company.name+'&company_website='+company.website" class="dropdown-item d-flex align-items-center">
                    <i
                      class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                      View Report
            </router-link>

            <li v-for="(name, status) in strToStatusMap" :key="status">
                <a class="dropdown-item d-flex align-items-center" 
                    href="javascript:void(0);"
                    @click="updateStatus(company.id, status)"
                >
                    <i :class="icon + ' lh-1 me-8 position-relative top-1'"></i>
                    {{ status }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { statusService } from '@/api/statusService';
import {companiesEmits} from '@/components/Companies/CompaniesUtils.js'

export default {
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    emits: companiesEmits,
    data() {
        return {
            strToStatusMap: {
                'Passed': 'PASD',
                'In Progress': 'INPRG',
                '1st Meeting': '1STMT',
                '2nd Meeting': '2NDMT',
                'Invested': 'INVST',
            },
            statusToStrMap: {
                'PASD': 'Passed',
                'INPRG': 'In Progress',
                '1STMT': '1st Meeting',
                '2NDMT': '2nd Meeting',
                'INVST': 'Invested',
            },
        }
    },
    methods: {
        updateStatus(id, status) {
            // this.$emit('update-company-status', id, status)
            console.log(id, status)
        }
    },
}
</script>