<template>
    <div class="container py-5">
      <h1 class="mb-4">Privacy Policy</h1>
      <p class="lead">Effective date: October 15, 2024</p>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">1. Introduction</h2>
          <p>Kapor Center Enterprises ("we", "us", or "our") operates kapor.ai and/or beta.kapor.ai. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website and services.</p>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">2. What Data Do We Collect?</h2>
          <p>We collect the following data:</p>
          <ul>
            <li>Personal identification information (Name, email address, phone number, etc.)</li>
            <li>Company information</li>
            <li>Usage data</li>
            <li>Technical data (IP address, browser type, device information)</li>
          </ul>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">3. How Do We Collect Your Data?</h2>
          <p>You directly provide us with most of the data we collect. We collect data and process data when you:</p>
          <ul>
            <li>Register online or place an order for any of our products or services</li>
            <li>Voluntarily complete a customer survey or provide feedback via email or our website</li>
            <li>Use or view our website via your browser's cookies</li>
            <li>Communicate with us via email, phone, or social media</li>
          </ul>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">4. How Will We Use Your Data?</h2>
          <p>We collect your data so that we can:</p>
          <ul>
            <li>Process your order and manage your account</li>
            <li>Email you with special offers on other products and services we think you might like</li>
            <li>Improve our services and website</li>
            <li>Communicate with you about our services</li>
            <li>Comply with legal obligations</li>
          </ul>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">5. How Do We Store Your Data?</h2>
          <p>We securely store your data using industry-standard security measures. We keep personal data for as long as necessary for the purposes for which it was collected. When no longer required, we will delete your data using secure methods.</p>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">6. What Are Your Data Protection Rights?</h2>
          <p>You have the following rights:</p>
          <ul>
            <li>The right to access your personal data</li>
            <li>The right to rectify your personal data</li>
            <li>The right to erase your personal data</li>
            <li>The right to restrict processing of your personal data</li>
            <li>The right to object to processing of your personal data</li>
            <li>The right to data portability</li>
            <li>The right to withdraw consent</li>
          </ul>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">7. What Are Cookies?</h2>
          <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.</p>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
            <h2 class="card-title">8. How Do We Use Cookies?</h2>
            <p>We use cookies in a range of ways to improve your experience on our website, including:</p>
            <ul>
              <li>Keeping you signed in</li>
              <li>Understanding how you use our website</li>
              <li>Personalizing our services for you</li>
            </ul>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">9. What Types of Cookies Do We Use?</h2>
          <p>We use the following cookies:</p>
          <ul>
            <li>Functional cookies</li>
            <li>Analytical/performance cookies</li>
            <li>Targeting cookies</li>
          </ul>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">10. How to Manage Cookies</h2>
          <p>You can set your browser not to accept cookies. However, in a few cases, some of our website features may not function as a result.</p>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">11. Privacy Policies of Other Websites</h2>
          <p>Our website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">12. Changes to Our Privacy Policy</h2>
          <p>We keep our privacy policy under regular review and place any updates on this web page.</p>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">13. How to Contact Us</h2>
          <p>If you have any questions about our privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us at:</p>
          <ul>
            <li>Email: info@kapor.ai</li>
          </ul>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">14. How to Contact the Appropriate Authority</h2>
          <p>Should you wish to report a complaint or if you feel that we have not addressed your concern in a satisfactory manner, you may contact the relevant data protection authority in your jurisdiction.</p>
          <ul>
            <li>For California residents, you may file a complaint with the California Attorney General's office.</li>
            <li>For residents of other states, please refer to your state's relevant data protection authority.</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPage'
  }
  </script>
  
  <style scoped>
  .card {
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .card-title {
    color: #333;
    font-weight: bold;
  }
  h3 {
    color: #555;
    margin-top: 1.5rem;
  }
  </style>