import axios from 'axios';
import { API_URL, makeAuthenticatedRequest } from '@/utils/apiUtils';
import { useUserStore } from '@/stores/userStore';

export const userService = {
    async getMe() {
        return await makeAuthenticatedRequest(`${API_URL}/me/`, 'GET')
    },
    async saveLoginRecord() {
        return await makeAuthenticatedRequest(`${API_URL}/profile/track_login/`, 'POST')
    },
    async getProfile() {
        const id = useUserStore().getUserId();
        return await makeAuthenticatedRequest(`${API_URL}/profile/${id}/`, 'GET')
    },
    async getSearchHistory(maxRecentReports = 5) {
        const id = useUserStore().getUserId();
        return await makeAuthenticatedRequest(`${API_URL}/profile/${id}/history/?unique=true&limit=${maxRecentReports}`, 'GET')
    },
    async saveSearchHistory(companyId) {
        const id = useUserStore().getUserId();
        const endpoint = `${API_URL}/profile/${id}/history/`;
        const method =  'POST';
        const payload = {
            company: companyId,
        };
        
        const response = await makeAuthenticatedRequest(endpoint, method, payload)
    
        if (response.status != 201) {
            throw new Error('Search Query could not be saved');
        }
    },
    refreshToken(refreshToken) {
        if (!refreshToken) {
            const error = new Error('No refresh token provided');
            console.error('Error in refreshToken:', error);
            throw error;
        }
        return axios.post(`${API_URL}/token/refresh/`, {
            refresh: refreshToken
        }).catch(error => {
            console.error('Error refreshing token:', error);
            throw error;
        });
    },
    loginGoogle(token) {
        if (!token) {
            const error = new Error('No refresh token provided');
            console.error('Error in token:', error);
            throw error;
        }
        return axios.post(`${API_URL}/login/google/`, {
            token: token
        }).catch(error => {
            console.error('Error refreshing token:', error);
            throw error;
        });
    },
    loginUser(user) {
        if (!user || !user.username || !user.password) {
            const error = new Error('User credentials are not provided');
            console.error('Error in login:', error);
            throw error;
        }
        return axios.post(`${API_URL}/token/`, {
            username: user.username,
            password: user.password
        }).catch(error => {
            console.error('Error in login request:', error);
            throw error;
        });
    },
    async passwordResetRequest(email) {
        return axios.post(`${API_URL}/password-reset/request/`, {
            email: email
        }).catch(error => {
            console.error('Error requesting password reset:', error);
            throw error;
        });
    },
    async passwordResetVerify(uid, token, timestamp) {
        return axios.get(`${API_URL}/password-reset/verify/${uid}/${token}/${timestamp}/`)
            .catch(error => {
                console.error('Error verifying password reset:', error);
                throw error;
            });
    },
    async passwordSet(uid, token, timestamp, newPassword) {
        return axios.post(`${API_URL}/password-reset/set-password/`, {
            uid: uid,
            token: token,
            timestamp: timestamp,
            new_password: newPassword
        }).catch(error => {
            console.error('Error setting password:', error);
            throw error;
        });
    }
};